import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { usePostHog } from "posthog-js/react";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import DefaultLayout from "../layouts/DefaultLayout.web";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import LearningBreadcrumbs from "../components/learning/general/LearningBreadcrumbs/LearningBreadcrumbs";
import TrackOverviewHeroSection from "../components/learning/trackOverview/TrackOverviewHero/TrackOverviewHero";
import TrackOverviewInfoCard from "../components/learning/trackOverview/TrackOverviewInfoCard/TrackOverviewInfoCard";
import TrackOverviewLessonsBox from "../components/learning/trackOverview/TrackOverviewLessonsBox/TrackOverviewLessonsBox";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { useNavigation } from "@react-navigation/native";
import {
  TrackOverviewDocument,
  MeDocument,
  NodeGraphDocument,
  useTrackOverviewQuery,
  useCreateEducationChildrenMutation,
  useEnrollUserInTrackMutation,
  useMeQuery,
} from "../graphql/generated/graphql";
import { ScrollView } from "react-native-gesture-handler";
import TrackLoader from "../components/common/status/TrackLoader/TrackLoader";

function TrackOverviewView({ route }) {
  const { isMedium, isLarge } = useResponsiveScreen();
  const posthog = usePostHog();
  const navigation = useNavigation();
  const params = route.params;
  const { data: meData } = useMeQuery({});
  const [enrollUserInTrack] = useEnrollUserInTrackMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
  });

  useEffect(() => {
    posthog?.capture("Track Overview Page Visited");
  }, []);

  const {
    data,
    loading: trackOverviewLoading,
    error,
  } = useTrackOverviewQuery({
    variables: {
      topicName: params.topic,
    },
  });

  const [createEducationChildren, { loading: loadingMutation }] = useCreateEducationChildrenMutation({
    refetchQueries: [
      {
        query: TrackOverviewDocument,
        variables: { topicName: params.topic },
      },
      {
        query: NodeGraphDocument,
        variables: { nodeName: params.subfield, nodeLabel: "Subfield" },
      },
    ],
  });

  useEffect(() => {
    if (data?.getTrackOverview && !data?.getTrackOverview.lessons) {
      createEducationChildren({
        variables: { name: params.topic, label: "Topic" },
      });
    }
  }, [data]);

  const loading = loadingMutation || trackOverviewLoading;
  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <TrackLoader isGenerating={trackOverviewLoading} />
        </View>
      </DefaultLayout>
    );
  }
  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  const hasPrerequisites = data?.getTrackOverview.prerequisites?.length > 0;

  const requirementsList = [
    {
      title: "Track Pre-requisites:",
      list: data?.getTrackOverview.prerequisites?.map((prerequisite) => ({
        name: prerequisite.name,
        subfield: prerequisite.subfield,
        field: prerequisite.field,
        enrolled: meData.me?.enrolledTracks?.includes(prerequisite.name) || prerequisite.mastery > 0,
        complete: prerequisite.mastery === 100,
      })),
    },
    {
      title: "Skill Requirements:",
      list: data?.getTrackOverview.skillRequirements?.map((skill) => ({
        name: skill,
      })),
    },
    {
      title: `${data?.getTrackOverview.otherRequirementsTitle}:`,
      list: data?.getTrackOverview.otherRequirements.map((requirement) => ({
        name: requirement,
      })),
    },
  ];

  const requirements = hasPrerequisites ? requirementsList : requirementsList.slice(1);

  const handleBeginTrack = () => {
    const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(params.topic);

    if (!isUserAlreadyEnrolled) {
      enrollUserInTrack({
        variables: {
          topicName: params.topic,
        },
      });
    }

    navigation.navigate("Lesson", {
      field: params.field,
      subfield: params.subfield,
      topic: params.topic,
      chapter: data?.getTrackOverview.lessons[0].name,
    });
  };

  const handleLinkClick = (params) => {
    const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(params.name);

    navigation.navigate(isUserAlreadyEnrolled ? "Lesson" : "Track Overview", {
      field: params.field,
      subfield: params.subfield,
      topic: params.name,
    });
  };

  return (
    <DefaultLayout>
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={10}
          transition={{ type: "timing", duration: 500 }}>
          <LearningBreadcrumbs links={Object.values(params)} />
          <CustomSpacing type="vertical" size="s" />
          <TrackOverviewHeroSection
            title={params.topic}
            description={data?.getTrackOverview.description}
            onCardPress={handleBeginTrack}
            objectives={data?.getTrackOverview.objectives}
          />
          <CustomSpacing type="vertical" size="xl" />
        </MotiView>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={300}
          transition={{ type: "timing", duration: 400 }}>
          <View style={isLarge ? styles.infoRow : styles.infoColumn}>
            {requirements.map((requirement, index) => (
              <TrackOverviewInfoCard
                key={index}
                title={requirement.title}
                list={requirement.list}
                onLinkPress={(params) => handleLinkClick(params)}
              />
            ))}
          </View>
          <CustomSpacing type="vertical" size="xl" />
        </MotiView>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={600}
          transition={{ type: "timing", duration: 600 }}>
          {data?.getTrackOverview.lessons && <TrackOverviewLessonsBox lessons={data?.getTrackOverview.lessons} />}
          <CustomSpacing type="vertical" size={isMedium ? "l" : "xl"} />
          <View style={styles.buttonBox}>
            <CustomButton
              text="Start Track"
              styleType="primary"
              bold
              style={styles.trackButton}
              textStyle={{ fontSize: 12 }}
              onPress={handleBeginTrack}
            />
          </View>
        </MotiView>
        <CustomSpacing type="vertical" size={isMedium ? "l" : "xl"} />
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 20,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  infoRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 32,
  },
  trackButton: {
    padding: 12,
  },
  buttonBox: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    widt: "auto",
  },
  infoColumn: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 32,
  },
});

export default TrackOverviewView;
