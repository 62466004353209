import React, { useMemo, useState } from "react";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";
import { useAuth } from "../../../hooks/useAuth";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useDailyLimitQuery, useMeQuery, useSchoolsQuery } from "../../../graphql/generated/graphql";
import { useAppConfig } from "../../../AppConfigProvider";
import useWebSocket from "../../../hooks/useWebSocket";
import MainMenu from "../../../components/navigation/MainMenu/MainMenu";
import { createCheckoutSession } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";
import {
  DashboardIcon,
  LearnIcon,
  HealthIcon,
  TodosIcon,
  EducationIcon,
  CareerIcon,
  MeetingIcon,
} from "../../../components/svgs/menuIcons";
import FeedbackModal from "../../../components/navigation/FeedbackModal/FeedbackModal";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";

type MenuItem = {
  name: keyof RootStackParamList;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  externalURL: string | undefined;
  index: number;
};

const constantMenuItems: MenuItem[] = [
  {
    name: "My Day",
    icon: DashboardIcon,
    externalURL: undefined,
    index: 0,
  },
  { name: "Productivity", icon: TodosIcon, externalURL: undefined, index: 3 },
];

function MainMenuContainer() {
  const appConfig = useAppConfig();
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute();
  const { logout } = useAuth();
  const { state, dispatch } = useAppState();

  const { data: dailyLimitData } = useDailyLimitQuery();
  const { data: meData } = useMeQuery();
  const { data: schoolsData, loading: schoolsLoading } = useSchoolsQuery();

  const [bugReportModalVisible, setBugReportModalVisible] = useState(false);

  const { additionalScope, scope, showFeedback, isLMS, isLTI, isFreemium, showMiniLogo, key } = appConfig;

  const additionalScopes = additionalScope?.map((item) => JSON.parse(item));

  const additionalScopeMenuItems =
    additionalScopes?.map((item) => ({
      name: item.name as keyof RootStackParamList,
      icon: MeetingIcon,
      externalURL: item.externalURL,
      index: item.index,
    })) || [];

  const DomainConfigMenuItems: MenuItem[] = [
    { name: "Activities", icon: EducationIcon, externalURL: undefined, index: 5 },
    { name: "Coursework", icon: EducationIcon, externalURL: undefined, index: 6 },
    // { name: "Tools", icon: JournalIcon, externalURL: undefined, index: 7 },
    { name: "Learn", icon: LearnIcon, externalURL: undefined, index: 1 },
    { name: "Health", icon: HealthIcon, externalURL: undefined, index: 2 },
    { name: "Career", icon: CareerIcon, externalURL: undefined, index: 98 },
    { name: "Custom Coursework", icon: EducationIcon, externalURL: undefined, index: 99 },
  ];

  const menuItems = useMemo(
    () =>
      [
        ...constantMenuItems,
        ...DomainConfigMenuItems.filter((item) => scope.includes(item.name)),
        ...additionalScopeMenuItems,
      ]
        .filter((item) => {
          if (item.name === "Coursework" || item.name === "Activities") {
            return schoolsData?.getSchools?.length > 0 && !schoolsLoading;
          }
          return true;
        })
        .sort((a, b) => a.index - b.index),
    [scope, additionalScope, schoolsData, schoolsLoading]
  );

  const handelUpgradePress = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");
    const data = await createCheckoutSession(token);

    const { url } = data;

    await handleOpenLink(url, newWindow);
  };

  const handleItemPress = (item) => {
    if (item.externalURL) {
      let newWindow;
      if (Platform.OS === "web") {
        newWindow = window.open("", "_blank");
      }
      handleOpenLink(item.externalURL, newWindow);
    } else if (item.name === "Coursework" || item.name === "Activities") {
      handleCourseworkNavigation();
    } else {
      navigation.navigate(item.name);
      handleNavigate();
    }
  };

  const handleCourseworkNavigation = () => {
    if (schoolsData?.getSchools && schoolsData.getSchools.length === 1) {
      navigation.navigate("School", {
        integration_id: schoolsData.getSchools[0].id,
      });
    } else {
      navigation.navigate("Coursework");
    }
    handleNavigate();
  };

  const handleFeedbackPress = async () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });

    setBugReportModalVisible(true);
  };

  const handleCloseBugReportModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setBugReportModalVisible(false);
  };

  const handleProfilePress = () => {
    navigation.navigate("Profile");
    handleNavigate();
  };

  const handleLogoutPress = async () => {
    logout();
  };

  const handleNavigate = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
    });

    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: false,
        isLearnMenuOpen: false,
      },
    });
  };

  const showLearnSectionOrName = (name) => {
    if (name === "Tracks" || name === "Categories" || name === "Track Overview") return "Learn";
    if (name === "School") return "Coursework";
    return name;
  };

  return (
    <>
      <MainMenu
        menuItems={menuItems}
        selectedItem={showLearnSectionOrName(route.name)}
        currentDomain={key}
        showMiniLogo={showMiniLogo}
        messageCount={state?.meta?.dailyRequestNumber || dailyLimitData?.getDailyLimit.dailyRequests}
        userInitial={meData?.me?.name?.charAt(0)}
        isPro={meData?.me?.isPro}
        isFreemium={isFreemium}
        isLMS={isLMS}
        isLTI={isLTI}
        showFeedback={showFeedback}
        onUpgradePress={handelUpgradePress}
        onItemPress={handleItemPress}
        onFeedbackPress={handleFeedbackPress}
        onProfilePress={handleProfilePress}
        onLogoutPress={handleLogoutPress}
      />
      <div className="js-rapidr-board-btn" style={{ display: "none" }}></div>
      <FeedbackModal visible={bugReportModalVisible} onClose={handleCloseBugReportModal} />
    </>
  );
}

export default MainMenuContainer;
