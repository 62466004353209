import { useState, useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { usePostHog } from "posthog-js/react";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import {
  useNodeGraphQuery,
  useRecentTracksQuery,
  useUserProgressQuery,
  NodeGraphDocument,
  useCreateEducationChildrenMutation,
} from "../graphql/generated/graphql";
import DefaultLayout from "../layouts/DefaultLayout.web";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { ScrollView } from "react-native-gesture-handler";
import getLearningRoute from "../utils/getLearningRoute";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import LearnHeader from "../components/learning/home/LearnHeader/LearnHeader";
import LearningBreadcrumbs from "../components/learning/general/LearningBreadcrumbs/LearningBreadcrumbs";
import LearnSelectableMenu from "../components/learning/general/LearnSelectableMenu/LearnSelectableMenu";
import LearnCategoryCardListContainer from "../containers/learning/LearnCategoryCardListContainer/LearnCategoryCardListContainer";
import { useAppState, actions } from "../contexts/AppStateContext";
import SelectableMenu from "../components/navigation/SelectableMenu/SelectableMenu";
import LearnTrackCardListContainer from "../containers/learning/LearnTrackCardListContainer/LearnTrackCardListContainer";
import TrackLoader from "../components/common/status/TrackLoader/TrackLoader";

function CategoriesView({ route }) {
  const { isMedium } = useResponsiveScreen();
  const posthog = usePostHog();
  const { dispatch } = useAppState();

  const [selectedView, setSelectedView] = useState("Category");
  const [searchValue, setSearchValue] = useState("");
  const [isSearchInputActive, setIsSearchInputActive] = useState(false);

  useEffect(() => {
    posthog?.capture("Learning Categories Page Visited");
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: actions.SET_META,
        payload: { keypressIsListening: true },
      });
    };
  }, []);

  const params = route.params;
  const { name, label } = getLearningRoute(params);

  // TODO display a placeholder with a button to reroute to the learn home page on error

  const {
    data: progressData,
    loading: progressLoading,
    error: progressError,
  } = useUserProgressQuery({
    variables: { nodeName: name, nodeLabel: label },
  });

  const {
    data: graphData,
    loading: graphLoading,
    error: graphError,
  } = useNodeGraphQuery({
    variables: { nodeLabel: label, nodeName: name },
  });

  const completedData = useMemo(() => {
    return graphData?.getNodeChildren.map((node) => {
      const progress = progressData?.getUserProgress.find((progress) => progress.child.name === node.name);
      return {
        ...node,
        totalTracks: progress?.child.totalTracks,
        completedTracks: progress?.child.completedTracks,
        mastery: progress?.totalMastery,
      };
    });
  }, [graphData, progressData]);

  const filteredCategories = completedData?.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const [createEducationChildren, { loading: loadingMutation }] = useCreateEducationChildrenMutation({
    refetchQueries: [
      {
        query: NodeGraphDocument,
        variables: { nodeLabel: label, nodeName: name },
      },
    ],
  });

  useEffect(() => {
    if (graphData?.getNodeChildren && graphData.getNodeChildren.length === 0) {
      createEducationChildren({
        variables: { name: name, label: label },
      });
    }
  }, [graphData]);

  const {
    data: graphRecentTracks,
    error: recentTracksError,
    loading: recentTracksLoading,
  } = useRecentTracksQuery({
    variables: { filterByCategory: params.field },
  });

  const filteredRecentTracks = graphRecentTracks?.getRecentTracks.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchClick = async (state) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: !state },
    });

    setIsSearchInputActive(state);
  };

  const handleTabChange = (item) => {
    setSelectedView(item);
    setIsSearchInputActive(false);
    setSearchValue("");
  };

  const loading = progressLoading || graphLoading || recentTracksError || loadingMutation || recentTracksLoading;
  const error = progressError || graphError || recentTracksError;

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <TrackLoader isGenerating={loadingMutation} />
        </View>
      </DefaultLayout>
    );
  }
  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  const description = graphData?.getNode?.description || "";

  const noRecentTracks = graphRecentTracks?.getRecentTracks?.length === 0;
  const selectableTabs = noRecentTracks ? ["Category"] : ["Category", `Recent ${params.field} Tracks`];

  return (
    <DefaultLayout>
      <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={10}
          transition={{ type: "timing", duration: 500 }}>
          <LearningBreadcrumbs links={[name]} />
          <LearnHeader title={name} description={description} />
        </MotiView>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={200}
          transition={{ type: "timing", duration: 500 }}>
          <CustomSpacing type="vertical" size={isMedium ? "xl" : "l"} />
          {isMedium ? (
            <LearnSelectableMenu
              items={selectableTabs}
              selectedItem={selectedView}
              onSelect={handleTabChange}
              onSearchClick={handleSearchClick}
              isSearchInputActive={isSearchInputActive}
              onChangeText={setSearchValue}
              searchText={searchValue}
            />
          ) : (
            <>
              <SelectableMenu items={selectableTabs} selectedItem={selectedView} onSelect={handleTabChange} />

              <CustomSpacing type="vertical" size="l" />
            </>
          )}
        </MotiView>

        {selectedView === "Category" && (
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={600}
            transition={{ type: "timing", duration: 600 }}>
            <View id="deneme" style={styles.listContainer}>
              <LearnCategoryCardListContainer data={filteredCategories} />
            </View>
          </MotiView>
        )}
        {selectedView === `Recent ${params.field} Tracks` && (
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={200}
            transition={{ type: "timing", duration: 600 }}>
            <View style={styles.listContainer}>
              <LearnTrackCardListContainer data={filteredRecentTracks} />
            </View>
          </MotiView>
        )}
        <CustomSpacing type="vertical" size={isMedium ? "l" : "xl"} />
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  scrollView: {
    height: 20,
  },
  listContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

export default CategoriesView;
