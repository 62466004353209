import React from "react";
import { View, StyleSheet, Text, FlatList, Pressable } from "react-native";
import CustomButton from "../common/general/CustomButton/CustomButton";
import CustomText from "../common/general/CustomText/CustomText";
import CustomTextInput from "../common/general/CustomTextInput/CustomTextInput";
import CustomSpacing from "../common/layout/CustomSpacing/CustomSpacing";
import { Attachment, BackArrowIcon, Trash } from "../svgIcons";

function ContentForm({
  onFileDelete,
  onSubmit,
  onObjectivesChange,
  courseObjectives,
  onFileUpload,
  isLoading,
  isUploading,
  setWeeks,
  weeks,
  setTitle,
  title,
  setStandard,
  standard,
  uploadedFiles,
  onBackPress,
  hasFilesOption = true,
}) {
  const renderFileItem = ({ item, index }) => (
    <View style={styles.fileItem}>
      <Attachment size={20} color="gray" />
      <Text style={styles.fileName}>
        {item.name} ({(item.size / 1024).toFixed(2)} KB)
      </Text>
      <Pressable onPress={() => onFileDelete(index)}>
        <Trash />
      </Pressable>
    </View>
  );

  return (
    <View style={styles.container}>
      {onBackPress && (
        <View style={styles.backButtonContainer}>
          <CustomButton
            text=" Go back"
            styleType="secondary"
            onPress={onBackPress}
            leftIcon={
              <View style={styles.row}>
                <BackArrowIcon />
                <CustomSpacing type="horizontal" size="s" />
              </View>
            }
          />
          <CustomSpacing type="vertical" size="l" />
          <CustomSpacing type="vertical" size="l" />
        </View>
      )}
      <CustomText role="header" text="Course Details" size="s" textType="display" weight="ultraBold" />

      <CustomSpacing type="vertical" size="l" />

      <CustomText text="Title" size="s" textType="text" style={{ color: "#182230" }} weight="ultraBold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput placeholder="Ex: Algebra ||" value={title} required onChangeText={(text) => setTitle(text)} />
      <CustomSpacing type="vertical" size="m" />

      <CustomText
        text="Course Objectives (be as specific as possible)"
        size="s"
        textType="text"
        style={{ color: "#182230" }}
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder="Enter required outcomes for this course."
        multiline
        maxLength={90}
        numberOfLines={4}
        onChangeText={onObjectivesChange}
        value={courseObjectives}
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Learning Standards" size="s" textType="text" style={{ color: "#182230" }} weight="ultraBold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder="Ex: Standards"
        value={standard}
        required
        onChangeText={(text) => setStandard(text)}
      />

      <CustomSpacing type="vertical" size="s" />
      <CustomText
        text="How many weeks is your course?"
        size="s"
        textType="text"
        style={{ color: "#182230" }}
        weight="ultraBold"
      />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        placeholder="Ex: 9"
        value={weeks}
        required
        onChangeText={(text) => setWeeks(parseInt(text, 10))}
      />

      <CustomSpacing type="vertical" size="m" />

      {hasFilesOption && (
        <>
          <CustomText
            text="Upload Course Files"
            size="s"
            textType="text"
            style={{ color: "#182230" }}
            weight="ultraBold"
          />
          <CustomSpacing type="vertical" size="m" />
          <View
            style={{
              borderWidth: 1,
              borderColor: "#D1D5DB",
              color: "#1F2937",
              borderStyle: "dashed",
              fontSize: 14,
              borderRadius: 8,
              width: "100%",
              minHeight: 174,
              padding: 10,
            }}>
            <FlatList
              data={uploadedFiles}
              renderItem={renderFileItem}
              keyExtractor={(item, index) => `${item.name}_${index}`}
              style={styles.fileList}
            />

            <Pressable onPress={onFileUpload}>
              <View style={{ flexDirection: "row", alignItems: "center", alignSelf: "center" }}>
                <Text style={styles.uploadText}>Browse files</Text>
                <Text> or drag and drop here</Text>
              </View>
            </Pressable>
          </View>
        </>
      )}

      <View style={styles.submitButtonContainer}>
        <CustomButton
          text={isUploading ? "Uploading files..." : isLoading ? "Generating course..." : "Submit"}
          styleType="primary"
          onPress={onSubmit}
          disabled={isLoading}
          style={isLoading ? styles.disabledButton : styles.primaryButton}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 15,
    paddingVertical: 15,
    marginHorizontal: 3,
    paddingHorizontal: 3,
    width: "100%",
    height: "auto",
  },
  fileItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 5,
  },
  fileName: {
    flex: 1,
    marginLeft: 10,
  },
  fileList: {
    marginTop: 10,
  },
  uploadText: {
    color: "#007bff",
    fontWeight: "bold",
  },
  submitButtonContainer: {
    marginTop: 20,
    alignSelf: "flex-start",
  },
  primaryButton: {
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  disabledButton: {
    backgroundColor: "#ccc",
    borderColor: "#aaa",
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  backButtonContainer: {
    alignSelf: "flex-start",
  },
});

export default ContentForm;
