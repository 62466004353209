import { View, StyleSheet, Pressable } from "react-native";
import { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { PlusCircle, Trash, EditIcon } from "../../svgIcons";
import CustomText from "../../common/general/CustomText/CustomText";
import { ScrollView } from "react-native-gesture-handler";
import { ListItem } from "@rneui/themed";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { actions, useAppState } from "../../../contexts/AppStateContext";
import ContentMenuHeader from "./ContentMenuHeader";
import ContentMenuItem from "./ContentMenuItem";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import DeleteModule from "../../content/DeleteModule/DeleteModule";
import AddModuleModalContainer from "../../content/ContentModalContainers/AddModuleModalContainer";
import EditAddAssignmentOrPageModalContainer from "../../content/ContentModalContainers/EditAddAssignmentOrPageModalContainer";
import EditModuleNameModalContainer from "../../content/ContentModalContainers/EditModuleNameModalContainer";
import { useNavigation } from "@react-navigation/native";

function ContentMenu({
  modulesRoute,
  data,
  loadingData,
  courseName,
  onItemPress,
  onRenameModule,
  onDeleteModule,
  onAddModule,
  onAddAssignmentOrPage,
  editModuleNameModalState,
  editAssignmentOrPageModalState,
  addModuleModalState,
  deleteModuleModalState,
}) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();

  const [selectedItem, setSelectedItem] = useState();
  const [selectedModule, setSelectedModule] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null);

  const [deleteModuleModalVisible, setDeleteModuleModalVisible] = deleteModuleModalState;
  const [addModuleModalVisible, setAddModuleModalVisible] = addModuleModalState;
  const [editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible] = editAssignmentOrPageModalState;
  const [editModuleNameModalVisible, setEditModuleNameModalVisible] = editModuleNameModalState;

  const selectedModuleData = data?.modules?.find((module) => (module.title ?? module.module_name) === selectedModule);
  const navigation = useNavigation();

  const { dispatch } = useAppState();

  useEffect(() => {
    if (data?.modules?.length > 0) {
      const firstModule = data.modules?.[0];
      setSelectedModule(firstModule.module_name ?? firstModule.title);

      if (data.modules[0].resources[0].assignments?.length > 0 || data?.modules.assignments?.length > 0) {
        const firstAssignment = data.modules?.[0].resources[0].assignments?.[0] ?? data?.modules.assignments[0];
        setSelectedItem(firstAssignment.assignment_name ?? firstAssignment.title);

        navigation.navigate(modulesRoute, {
          courseId: data?.id,
          courseName: data.name ?? data.title,
          assignmentIndex: firstModule.resources[0].assignments.indexOf(firstAssignment),
          moduleIndex: data?.modules?.indexOf(firstModule),
        });
      } else if (data.modules[0].resources[0].pages?.length > 0 || data?.modules.pages?.length > 0) {
        const firstPage = data.modules?.[0].resources[0].pages?.[0] ?? data?.modules.pages[0];
        setSelectedItem(firstPage.title);

        navigation.navigate(modulesRoute, {
          courseId: data?.id,
          courseName: data.name ?? data.title,
          pageIndex: firstModule.resources[0].pages.indexOf(firstPage),
          moduleIndex: data?.modules?.indexOf(firstModule),
        });
      }
    }
  }, [data]);

  const handleAddModule = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setAddModuleModalVisible(true);
  };
  const handleEditModuleName = (moduleName) => {
    setSelectedModule(moduleName);
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditModuleNameModalVisible(true);
  };

  const handleDeleteModule = (moduleName) => {
    setDeleteModuleModalVisible(true);
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setSelectedModule(moduleName);
  };

  const handleEditAssignmentOrPage = (moduleName, type) => {
    setSelectedModule(moduleName);
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditAssignmentOrPageModalVisible(type);
  };

  const handleDeleteModuleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setDeleteModuleModalVisible(false);
  };

  const handleAddModuleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setAddModuleModalVisible(false);
  };
  const handleEditModuleNameModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setEditModuleNameModalVisible(false);
  };

  const handleEditAssignmentOrPageModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setEditAssignmentOrPageModalVisible(null);
  };

  const handleItemPress = (name, moduleName, type) => {
    setSelectedItem(name);
    onItemPress(name, moduleName, data.name ?? data.title, type);
  };

  return (
    <View
      style={[styles.container, { maxWidth: isXLarge ? 400 : isMedium ? 240 : hp(100) }]}
      aria-live="polite"
      aria-label="Content Menu"
      role="menu">
      <View style={styles.menuContainer}>
        <CustomText useTranslationText={false} text={courseName} size="xs" weight="bold" textType="display" />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <CustomText
            role="header"
            aria-level="3"
            text="Modules"
            size="m"
            weight="ultraBold"
            textType="text"
            style={{ marginLeft: 20 }}
          />
          <CustomButton
            text="Add"
            leftIcon={<PlusCircle />}
            styleType="primaryLight"
            size="s"
            style={[styles.addButton, { marginLeft: isMedium ? "0" : "auto" }]}
            textStyle={styles.addText}
            bold={true}
            onPress={handleAddModule}
            aria-label="Add Module"
            accessibilityHint="Press to add Module"
          />
        </View>
      </View>

      <CustomModal
        visible={deleteModuleModalVisible}
        onRequestClose={handleDeleteModuleModalClose}
        showCloseButton={true}
        title="Delete Module"
        style={{ width: isMedium ? "50%" : "90%" }}>
        <DeleteModule
          deleteButtonClick={onDeleteModule}
          backButtonClick={handleDeleteModuleModalClose}
          moduleName={selectedModule}
          moduleId={selectedModuleData?.id}
        />
      </CustomModal>

      <EditModuleNameModalContainer
        visible={editModuleNameModalVisible}
        onClose={handleEditModuleNameModalClose}
        moduleName={selectedModule}
        onUpdateModuleName={onRenameModule}
        hasOrder={modulesRoute === "Custom Coursework Modules"}
        currentOrder={selectedModuleData?.order}
        moduleId={selectedModuleData?.id}
      />

      <EditAddAssignmentOrPageModalContainer
        visible={!!editAssignmentOrPageModalVisible}
        onClose={handleEditAssignmentOrPageModalClose}
        initialContent={""}
        onSaveItem={onAddAssignmentOrPage}
        type={editAssignmentOrPageModalVisible}
        moduleName={selectedModule}
        resourceId={selectedModuleData?.resources?.[0]?.id}
      />

      <AddModuleModalContainer
        visible={addModuleModalVisible}
        onClose={handleAddModuleModalClose}
        onAddModule={onAddModule}
        hasOrder={modulesRoute === "Custom Coursework Modules"}
      />

      {loadingData ? (
        <View>
          <Skeleton height={34} width="98%" style={{ marginVertical: 10 }} />
        </View>
      ) : (
        <ScrollView role="list" showsVerticalScrollIndicator={false} style={styles.scrollView}>
          {data?.modules?.map((module, index) => {
            const moduleName = module.module_name ?? module.title;

            const assignments = module.assignments ?? module.resources?.[0].assignments ?? [];

            return (
              <ListItem.Accordion
                key={moduleName}
                role="button"
                aria-label={moduleName}
                pad={8}
                containerStyle={styles.chapterAccordion}
                isExpanded={expandedModule === moduleName}
                content={
                  <ListItem.Content
                    style={[
                      styles.chapterNameContainer,
                      { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                    ]}>
                    <View style={{ flex: 3, paddingRight: 10 }}>
                      <CustomText
                        useTranslationText={false}
                        text={moduleName}
                        size="m"
                        weight="medium"
                        textType="text"
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={{ textDecorationLine: "none", color: "#000" }}
                      />
                    </View>
                    <View
                      style={{
                        flexShrink: 0,
                        flexDirection: "row",
                        gap: 8,
                        alignItems: "center",
                      }}>
                      <Pressable
                        onPress={() => {
                          handleEditModuleName(moduleName);
                        }}>
                        <EditIcon width={20} height={20} color={"#667085"} />
                      </Pressable>

                      <Pressable
                        onPress={() => {
                          handleDeleteModule(moduleName);
                        }}>
                        <Trash width={20} height={20} color={"#667085"} />
                      </Pressable>
                    </View>
                  </ListItem.Content>
                }
                onPress={() => setExpandedModule((prev) => (prev === moduleName ? null : moduleName))}
                topDivider={index !== 0}>
                <ContentMenuHeader
                  type="Assignment"
                  onAddPress={() => handleEditAssignmentOrPage(moduleName, "Assignment")}
                />
                {assignments.map((assignment) => {
                  const assignmentName = assignment.assignment_name ?? assignment.title;
                  return (
                    <ContentMenuItem
                      onItemPress={() =>
                        handleItemPress(assignmentName, module.module_name ?? module.title, "Assignment")
                      }
                      name={assignmentName}
                      key={assignmentName}
                      isSelected={selectedItem === assignmentName}
                    />
                  );
                })}
                <ContentMenuHeader type="Page" onAddPress={() => handleEditAssignmentOrPage(moduleName, "Page")} />
                {module.resources?.[0].pages && (
                  <>
                    {module.resources?.[0].pages.map((page) => (
                      <ContentMenuItem
                        onItemPress={() => handleItemPress(page.title, module.title, "Page")}
                        name={page.title}
                        key={page.title}
                        isSelected={selectedItem === page.title}
                      />
                    ))}
                  </>
                )}
              </ListItem.Accordion>
            );
          })}
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 1,
    paddingHorizontal: 20,
  },
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#EAECF0",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    backgroundColor: "#fff",
    zIndex: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 8,
      height: 0,
    },
    shadowOpacity: 0.02,
    shadowRadius: 8,
    elevation: 5,
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    paddingHorizontal: 20,
    paddingTop: 40,
  },
  chapterAccordion: {
    flexDirection: "row-reverse",
    paddingRight: 16,
    paddingLeft: 0,
    paddingVertical: 12,
  },
  chapterNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 34,
  },
  addButton: {
    fontSize: 12,
  },
  addText: {
    paddingLeft: 10,
  },
});

export default ContentMenu;
