import React, { createContext, useReducer, useContext } from "react";
import { useAudioToggleUserMutation } from "../graphql/generated/graphql";
import Sentry from "../utils/sentry";

export const initialState = {
  meta: {
    isAdventure: null,
    isExpanded: false,
    isModalOpen: null,
    isQuestModalOpen: false,
    isGenerated: null,
    abort: false,
    activeMessages: 0,
    generationLabel: "",
    welcomeStep: false,
    keypressIsListening: true,
    isAudioOn: true,
    executionInfo: {
      agentName: null,
      status: null,
      message: null,
    },
    user: null,
  },
  state: {
    general: "GENERAL_CONVERSATION",
    learning: {
      state: null,
      topic: null,
      topicAncestors: [],
      label: null,
      isUnitAssessment: false,
      subchapterName: null,
      subchapterDescription: null,
      objectives: null,
    },
  },
  audio: {
    type: null,
    data: null,
  },
  content: {},
  conversation: [],
  currentMessage: null,
};

export const actions = {
  SET_META: "SET_META",
  SET_GENERAL_STATE: "SET_GENERAL_STATE",
  SET_LEARNING_STATE: "SET_LEARNING_STATE",
  SET_LEARNING_TOPIC: "SET_LEARNING_TOPIC",
  SET_LEARNING_TOPIC_ANCESTORS: "SET_LEARNING_TOPIC_ANCESTORS",
  SET_LEARNING_LABEL: "SET_LEARNING_LABEL",
  SET_LEARNING_ASSESSMENT_DETAILS: "SET_LEARNING_ASSESSMENT_DETAILS",
  SET_VIEW: "SET_VIEW",
  RESET_STATE: "RESET_STATE",
  SET_CONTENT: "SET_CONTENT",
  SET_CONVERSATION: "SET_CONVERSATION",
  SET_CURRENT_MESSAGE: "SET_CURRENT_MESSAGE",
  UPDATE_CURRENT_MESSAGE: "UPDATE_CURRENT_MESSAGE",
  RESET_CURRENT_MESSAGE: "RESET_CURRENT_MESSAGE",
  SET_AUDIO: "SET_AUDIO",
};

const mergeDefinedProps = (obj1, obj2) => {
  const result = { ...obj1 };
  for (const [key, value] of Object.entries(obj2)) {
    if (value !== undefined && value !== null) {
      result[key] = value;
    }
  }
  return result;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_META:
      return { ...state, meta: mergeDefinedProps(state.meta, action.payload) };
    case actions.SET_GENERAL_STATE:
      return { ...state, state: { ...state.state, general: action.payload } };
    case actions.SET_LEARNING_STATE:
      return {
        ...state,
        state: {
          ...state.state,
          learning: { ...state.state.learning, state: action.payload },
        },
      };
    case actions.SET_LEARNING_TOPIC:
      return {
        ...state,
        state: {
          ...state.state,
          learning: { ...state.state.learning, topic: action.payload },
        },
      };
    case actions.SET_LEARNING_TOPIC_ANCESTORS:
      return {
        ...state,
        state: {
          ...state.state,
          learning: { ...state.state.learning, topicAncestors: action.payload },
        },
      };
    case actions.SET_LEARNING_LABEL:
      return {
        ...state,
        state: {
          ...state.state,
          learning: { ...state.state.learning, label: action.payload },
        },
      };
    case actions.SET_LEARNING_ASSESSMENT_DETAILS:
      return {
        ...state,
        state: {
          ...state.state,
          learning: { ...state.state.learning, ...action.payload },
        },
      };
    case actions.SET_VIEW:
      return { ...state, state: { ...state.state, view: action.payload } };
    case actions.SET_CONTENT:
      return {
        ...state,
        content: { ...state.content, ...action.payload },
      };
    case actions.SET_CURRENT_MESSAGE:
      return {
        ...state,
        currentMessage: action.payload,
      };
    case actions.UPDATE_CURRENT_MESSAGE:
      return {
        ...state,
        currentMessage: {
          ...state.currentMessage,
          content: action.payload,
        },
      };
    case actions.RESET_CURRENT_MESSAGE:
      return {
        ...state,
        currentMessage: null,
      };
    case actions.SET_CONVERSATION:
      return {
        ...state,
        conversation: action.payload,
      };
    case actions.SET_AUDIO:
      return {
        ...state,
        audio: action.payload,
      };
    case actions.RESET_STATE:
      return { ...initialState };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const AppStateContext = createContext(null);

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [toggleAudio] = useAudioToggleUserMutation();

  const handleAudioOffPress = () => {
    dispatch({
      type: actions.SET_META,
      payload: { isAudioOn: false },
    });

    toggleAudio({
      variables: {
        audioOn: false,
      },
    }).catch((error) => {
      Sentry.captureException(error);
      // Revert the state change on error
      dispatch({
        type: actions.SET_META,
        payload: { isAudioOn: true },
      });
    });
  };

  const handleAudioOnPress = () => {
    dispatch({
      type: actions.SET_META,
      payload: { isAudioOn: true },
    });

    toggleAudio({
      variables: {
        audioOn: true,
      },
    }).catch((error) => {
      Sentry.captureException(error);
      // Revert the state change on error
      dispatch({
        type: actions.SET_META,
        payload: { isAudioOn: false },
      });
    });
  };

  return (
    <AppStateContext.Provider value={{ state, dispatch, handleAudioOffPress, handleAudioOnPress }}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppStateContext);
};
