import React, { useState, useRef } from "react";
import { View, StyleSheet, SafeAreaView } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import { RichEditor, RichToolbar, actions } from "react-native-pell-rich-editor";
import CustomText from "../../common/general/CustomText/CustomText";
import { DatePickerInput } from "react-native-paper-dates";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const EditAssignmentOrPage = ({
  onClose,
  onSave,
  initialContent,
  newName,
  onChangeName,
  type,
  buttonText = "Save",
  hasDueDate = false,
  dueDate,
  onDatePickerChange,
}) => {
  const [editorContent, setEditorContent] = useState(initialContent || "");
  const editorRef = useRef(null);

  const handleSave = () => {
    editorRef.current.getContentHtml().then((htmlContent) => {
      onSave(htmlContent);
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text={`${type} Name`} size="xs" />
      <CustomTextInput value={newName} onChangeText={onChangeName} placeholder={`Enter ${type} Name`} />
      <CustomSpacing type="vertical" size="l" />

      <View style={styles.editorContainer}>
        <RichEditor
          ref={editorRef}
          style={styles.editor}
          initialContentHTML={editorContent}
          onChange={(html) => setEditorContent(html)}
        />
      </View>

      {/* Toolbar */}
      <RichToolbar
        editor={editorRef}
        actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
        style={styles.toolbar}
      />

      {hasDueDate && (
        <DatePickerInput
          locale="en"
          label="Due Date"
          value={dueDate}
          onChange={(d) => onDatePickerChange(d)}
          inputMode="start"
          mode="outlined"
          outlineColor={"#D1D5DB"}
          selectionColor={false}
          activeOutlineColor={"blue"}
          inputProps={{
            color: "#000000",
            mode: "outline",
          }}
          theme={{
            colors: "#000000",
            roundness: 8,
            opacity: 1,
          }}
          style={styles.datePicker}
          aria-label="Due date date picker"
        />
      )}

      {/* Save and Cancel Buttons */}
      <View style={styles.buttonContainer}>
        <CustomButton
          text={buttonText} // Dynamic button text (Add or Save)
          onPress={handleSave}
          styleType="primary"
          size="m"
        />
        <CustomButton text="Cancel" onPress={onClose} styleType="primaryLight" size="m" />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
    flex: 1,
  },
  editorContainer: {
    flex: 1,
    marginBottom: 10,
    minHeight: 300,
  },
  editor: {
    flex: 1,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
  },
  toolbar: {
    backgroundColor: "#f1f1f1",
    borderColor: "#ccc",
    borderTopWidth: 1,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  datePicker: {
    borderWidth: 0,
    paddingVertical: 5,
    backgroundColor: "#F8F8F8",
    borderColor: "#000000",
    borderRadius: 10,
    color: "#000000",
    fontSize: 14,
  },
});

export default EditAssignmentOrPage;
