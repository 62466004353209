import React, { useState, useEffect } from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { sendEmailOTP, sendSMSOTP, verifyEmailOTP, verifySMSOTP, updateOtpPreference } from "../../../services";
import TwoFactorSuccess from "../../../components/profile/TwoFactorSuccess/TwoFactorSuccess";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useMeQuery } from "../../../graphql/generated/graphql";
import TwoFactorSetup from "../../../components/profile/TwoFactorSetup/TwoFactorSetup";
import TwoFactorEmail from "../../../components/profile/TwoFactorEmail/TwoFactorEmail";
import TwoFactorPhoneNumber from "../../../components/profile/TwoFactorPhoneNumber/TwoFactorPhoneNumber";
import TwoFactorEnterCode from "../../../components/profile/TwoFactorEnterCode/TwoFactorEnterCode";
import Sentry from "../../../utils/sentry";

interface TwoFactorAuthModalContainerProps {
  visible: boolean;
  onClose: () => void;
}

enum TwoFactorAuthStep {
  SETUP = "setup",
  EMAIL = "email",
  PHONE = "phone",
  ENTER_CODE = "enterCode",
  SUCCESS = "success",
}

export enum OTPPreference {
  EMAIL = "EMAIL",
  SMS = "SMS",
  PHONE = "PHONE",
  NONE = "NONE",
}

export enum TwoFactorAuthIntent {
  ENABLE = "enable",
  DISABLE = "disable",
}

const TwoFactorAuthModalContainer: React.FC<TwoFactorAuthModalContainerProps> = ({ visible, onClose }) => {
  const { isMedium } = useResponsiveScreen();
  const [step, setStep] = useState<TwoFactorAuthStep>(TwoFactorAuthStep.SETUP);
  const { data, refetch } = useMeQuery();

  const [tempPhoneNumber, setTempPhoneNumber] = useState<string[]>([]);

  const [selectedMethod, setSelectedMethod] = useState<OTPPreference>(data?.me?.otpPreference as OTPPreference);
  const { dispatch } = useAppState();

  useEffect(() => {
    setSelectedMethod(data?.me?.otpPreference as OTPPreference);
  }, [data?.me?.otpPreference]);

  const handleClose = () => {
    setStep(TwoFactorAuthStep.SETUP);
    setSelectedMethod(data?.me?.otpPreference as OTPPreference);
    onClose();
  };

  useEffect(() => {
    if (["email", "phone", "enterCode"].includes(step)) {
      dispatch({ type: actions.SET_META, payload: { keypressIsListening: false } });
    } else {
      dispatch({ type: actions.SET_META, payload: { keypressIsListening: true } });
    }

    return () => {
      dispatch({ type: actions.SET_META, payload: { keypressIsListening: true } });
    };
  }, [step, dispatch]);

  const handleContinue = (method: OTPPreference) => {
    setSelectedMethod(method);
    setStep(method === OTPPreference.EMAIL ? TwoFactorAuthStep.EMAIL : TwoFactorAuthStep.PHONE);
  };

  const handleCodePress = async (value: string) => {
    try {
      const token = await AsyncStorage.getItem("token");
      if (selectedMethod === OTPPreference.EMAIL) {
        await sendEmailOTP(value, token);
      } else {
        const [countryCode, number] = value.split(" ");

        setTempPhoneNumber([countryCode, number]);

        await sendSMSOTP(countryCode, number, token);
      }
      setStep(TwoFactorAuthStep.ENTER_CODE);
    } catch (error) {
      throw new Error("OTP sending failed. Please try again.");
    }
  };

  const handleVerifyPress = async (code: string) => {
    try {
      const token = await AsyncStorage.getItem("token");
      if (selectedMethod === OTPPreference.EMAIL) {
        await verifyEmailOTP(data?.me?.email, code, token);
      } else {
        if (tempPhoneNumber.length === 0) {
          setStep(TwoFactorAuthStep.SETUP);
          return;
        }
        const [countryCode, phoneNumber] = tempPhoneNumber;
        await verifySMSOTP(countryCode, phoneNumber, code, token);
      }

      if (data?.me?.otpPreference === "NONE") {
        await updateOtpPreference(selectedMethod, token);
      }
      setStep(TwoFactorAuthStep.SUCCESS);
      await refetch();
      setTempPhoneNumber([data?.me?.countryCode, data?.me?.phoneNumber]);
      setTimeout(() => {
        handleClose();
      }, 3000);
    } catch (error) {
      throw new Error("OTP verification failed. Please try again.");
    }
  };

  const handleResendPress = () => {
    handleCodePress(selectedMethod === OTPPreference.EMAIL ? data?.me?.email : data?.me?.phoneNumber);
  };

  const renderContent = () => {
    switch (step) {
      case TwoFactorAuthStep.SETUP:
        return <TwoFactorSetup selectedMethod={selectedMethod} setSelectedMethod={handleContinue} />;
      case TwoFactorAuthStep.EMAIL:
        return <TwoFactorEmail handleCodePress={handleCodePress} userEmail={data?.me?.email} />;
      case TwoFactorAuthStep.PHONE:
        return (
          <TwoFactorPhoneNumber
            handleCodePress={handleCodePress}
            userPhoneNumber={data?.me?.phoneNumber}
            userCountryCode={data?.me?.countryCode}
          />
        );
      case TwoFactorAuthStep.ENTER_CODE:
        return (
          <TwoFactorEnterCode
            handleVerifyPress={handleVerifyPress}
            handleResendPress={handleResendPress}
            contactMethod={selectedMethod === OTPPreference.EMAIL ? "email" : "phone number"}
          />
        );
      case TwoFactorAuthStep.SUCCESS:
        return <TwoFactorSuccess onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={handleClose}
      showCloseButton={true}
      title="Two-factor Identification Setup"
      style={{ width: isMedium ? "50%" : "90%" }}
      exitFill="#000000"
      onShow={() => {}}
      ariaLabel="Two-factor Authentication Modal">
      {renderContent()}
    </CustomModal>
  );
};

export default TwoFactorAuthModalContainer;
