import React, { useCallback } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigation, useFocusEffect, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";

const noAuth = (WrappedComponent) => {
  const NoAuthComponent = (props) => {
    const { isUpdating, isAuthenticated, isEmailVerified, otpVerifiedForSession } = useAuth();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const route = useRoute();

    useFocusEffect(
      useCallback(() => {
        if (route.name === "Verify Email" && !isAuthenticated) {
          navigation.reset({
            index: 0,
            routes: [{ name: "Login" }],
          });
        } else if (isAuthenticated && isEmailVerified && otpVerifiedForSession) {
          navigation.reset({
            index: 0,
            routes: [{ name: "My Day" }],
          });
        }
      }, [isAuthenticated, navigation, route.name])
    );

    if (isUpdating) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return NoAuthComponent;
};

export default noAuth;
