import React, { useState, useEffect } from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import EditAssignmentOrPage from "../EditAssignmentOrPage/EditAssignmentOrPage";

const tomorrow = new Date(new Date().getTime() + 86400000);

const EditAddAssignmentOrPageModalContainer = ({
  visible,
  onClose,
  initialContent,
  currentName = "",
  currentDueDate,
  type,
  onSaveItem,
  moduleName,
  resourceId,
}) => {
  const { isMedium } = useResponsiveScreen();
  const [name, setName] = useState("");
  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    if (currentName !== "") {
      setName(currentName);
    }

    if (currentDueDate) {
      setDueDate(new Date(currentDueDate));
    } else {
      setDueDate(tomorrow);
    }

    return () => {
      setName("");
      setDueDate(tomorrow);
    };
  }, [visible]);

  const isNewItem = currentName === "";

  const onChangeName = (text) => {
    setName(text);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={isNewItem ? `Add ${type}` : `Edit ${type}`}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <EditAssignmentOrPage
        onClose={onClose}
        onSave={(htmlContent) => onSaveItem(type, htmlContent, name, dueDate, moduleName, resourceId)}
        initialContent={initialContent}
        newName={name}
        onChangeName={onChangeName}
        buttonText={isNewItem ? "Add" : "Save"}
        type={type}
        hasDueDate={type === "Assignment"}
        dueDate={dueDate}
        onDatePickerChange={handleDueDateChange}
      />
    </CustomModal>
  );
};

export default EditAddAssignmentOrPageModalContainer;
