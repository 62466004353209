import { View, StyleSheet, Image } from "react-native";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import Loader from "../../common/status/Loader/Loader";
import LinearGradient from "react-native-web-linear-gradient";

function OnboardingModal({
  visible,
  title,
  description,
  footer,
  image,
  onCtaClick,
  ctaButtonText,
  aspectRatio = 1,
  height,
  loading,
}) {
  const { isMedium } = useResponsiveScreen();
  const handleSubmit = async () => {
    onCtaClick();
  };

  return (
    <CustomModal
      animationType="fade"
      transparent={true}
      visible={visible}
      style={[styles.modalContainer, { width: isMedium ? "75%" : "90%" }]}>
      {isMedium ? (
        <>
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={["#00071F", "#031555"]}
            style={{ borderRadius: 20 }}>
            <View style={styles.bodyContainer}>
              <View style={styles.imageContainer}>
                <Image
                  source={image}
                  style={[styles.image, { aspectRatio, maxHeight: height }]}
                  resizeMode="contain"
                  accessibilityLabel={title}
                  alt={description}
                  role={"image"}
                />
              </View>

              <CustomSpacing type="vertical" size="m" />
              <View>
                <CustomText
                  useTranslationText={false}
                  text={title}
                  bold
                  size="xs"
                  textType="display"
                  styleType="light"
                  style={styles.title}
                />
                <CustomSpacing type="vertical" size="m" />
                <CustomText useTranslationText={false} style={styles.description} text={description} size="m" />
              </View>
              <CustomSpacing type="vertical" size="m" />
              {footer}
              <CustomSpacing type="vertical" size="m" />
              {loading ? (
                <Loader />
              ) : (
                <View style={styles.buttonContainer}>
                  <CustomButton
                    text={ctaButtonText}
                    styleType="primary"
                    onPress={handleSubmit}
                    bold
                    size="m"
                    aria-label={ctaButtonText}
                  />
                </View>
              )}
            </View>
          </LinearGradient>
        </>
      ) : (
        <>
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={["#00071F", "#031555"]}
            style={{ borderRadius: 20 }}>
            <View style={styles.bodyContainerMobile}>
              <Image
                source={image}
                style={[styles.imageMobile, { aspectRatio, maxHeight: height }]}
                resizeMode="contain"
                accessibilityLabel={title}
                alt={description}
                role={"image"}
              />
              <CustomSpacing type="vertical" size="m" />
              <CustomText text={title} bold size="xs" textType="display" styleType="light" style={styles.title} />
              <CustomSpacing type="vertical" size="m" />
              <CustomText style={styles.description} text={description} size="m" />
              <CustomSpacing type="vertical" size="m" />
              {footer}
              <CustomSpacing type="vertical" size="m" />
              <View aria-live="polite">
                {loading ? (
                  <Loader />
                ) : (
                  <CustomButton
                    aria-label={ctaButtonText}
                    text={ctaButtonText}
                    styleType="primary"
                    onPress={handleSubmit}
                    bold
                    size="m"
                  />
                )}
              </View>
            </View>
          </LinearGradient>
        </>
      )}
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    maxWidth: 600,
    padding: 0,
  },
  bodyContainer: {
    padding: 32,
    alignItems: "center",
    justifyContent: "flex-start",
    height: "50%",
  },
  bodyContainerMobile: {
    padding: 32,
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imageContainer: {
    width: "100%",
    height: 446,
    justifyContent: "center",
    alignContent: "center",
  },
  image: {
    width: "100%",
    maxHeight: 446,
  },
  imageMobile: {
    width: "100%",
    flex: 1,
  },
  description: {
    textAlign: "center",
    minHeight: 63,
    color: "#fff",
    fontWeight: "500",
  },
  title: {
    textAlign: "center",
  },
  buttonContainer: {
    height: 40,
    //width: "100%",
  },
});

export default OnboardingModal;
