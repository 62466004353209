import { useState, useEffect, useRef } from "react";
import { View, StyleSheet } from "react-native";
import { useAppState, actions } from "../contexts/AppStateContext";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import OnboardingLayout from "../layouts/OnboardingLayout.web";
import ChatBarContainer from "../containers/navigation/ChatBarContainer/ChatBarContainer";
import ChatContainer from "../containers/chat/ChatContainer/ChatContainer";
import OnboardBegin from "../components/onboarding/OnboardBegin/OnboardBegin";
import OnboardWelcome from "../components/onboarding/OnboardWelcome/OnboardWelcome";
import LottieView from "lottie-react-native";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Vector3, Mesh, BufferGeometry, MeshBasicMaterial, BufferAttribute } from "three";
import { useGetChatByContextAndParamsQuery } from "../graphql/generated/graphql";
import { useChatBar, ConversationType } from "../contexts/ChatBarContext";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { useNavigation } from "@react-navigation/native";
import { useAppConfig } from "../AppConfigProvider";

function CanvasController() {
  const { scene } = useThree();

  const particles = useRef([]);

  useFrame((_, delta) => {
    particles.current.forEach((p) => {
      p.position.add(p.velocity);
      p.material.opacity -= delta;
      if (p.material.opacity <= 0) {
        scene.remove(p);
      }
    });
  });

  useEffect(() => {
    for (let i = 0; i < 100; i++) {
      // Create a sphere geometry for orb-like particles
      const geometry = new BufferGeometry();

      // Generate random positions for each particle
      const positions = [];
      for (let i = 0; i < 100; i++) {
        const x = (Math.random() - 0.5) * 2;
        const y = (Math.random() - 0.5) * 2;
        const z = (Math.random() - 0.5) * 2;
        positions.push(x, y, z);
      }

      geometry.setAttribute("position", new BufferAttribute(new Float32Array(positions), 3));

      const material = new MeshBasicMaterial({
        color: 0x0000ff, // Blue color
        transparent: true,
        opacity: 1,
      });

      const particle = new Mesh(geometry, material);

      particle.scale.set(0.05, 0.05, 0.05); // Adjust the size of the orbs

      particle.velocity = new Vector3(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5).multiplyScalar(
        0.1
      );

      scene.add(particle);
      particles.current.push(particle);
    }
  }, []);

  return;
}

function OnboardingView() {
  const { state, dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();
  const { setConversationType, setShowChat } = useChatBar();
  const navigation = useNavigation();
  const appConfig = useAppConfig();
  const isMoodleOffering = appConfig.isMoodleOffering;

  const [isOnboardBegin, setIsOnboardBegin] = useState(false);
  const [isOnboardEffect, setIsOnboardEffect] = useState(false);
  const [isOnboardWelcome, setIsOnboardWelcome] = useState(false);
  const [isOnboardingChat, setIsOnboardingChat] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(false);

  useEffect(() => {
    if (state.content.type === "FINAL" && !state.meta.isOnboarded) {
      dispatch({
        type: actions.SET_META,
        payload: { isExpanded: true },
      });
    }
  }, [state.content.type]);

  const { data: chatData, refetch: refetchChatData } = useGetChatByContextAndParamsQuery({
    variables: {
      context: "onboarding",
      params: null,
    },
  });

  useEffect(() => {
    if (state.meta.chatHistory) {
      refetchChatData();
    }
  }, [state.meta.chatHistory]);

  useEffect(() => {
    if (state.meta.isOnboarded) {
      setIsOnboarded(true);
    }
  }, [state.meta.isOnboarded]);

  const handleBeginPress = async () => {
    dispatch({
      type: actions.SET_META,
      payload: { isOnboardEffect: true },
    });

    setIsOnboardBegin(true);
    setIsOnboardEffect(true);

    dispatch({
      type: actions.SET_META,
      payload: { isExpanded: true },
    });
    setIsOnboardEffect(false);
    setIsOnboardWelcome(true);
  };

  const handleChatSubmit = () => {
    setIsOnboardWelcome(false);
    setIsOnboardingChat(true);
  };

  const handleFinishOnboarding = () => {
    setShowChat(false);
    setConversationType(ConversationType.GENERAL_CONVERSATION);
    if (isMoodleOffering) {
      navigation.navigate("School", { integration_id: "1" });
    } else {
      navigation.navigate("My Day");
    }
  };

  return (
    <OnboardingLayout>
      {!isOnboardBegin && (
        <View style={styles.centerContainer}>
          <OnboardBegin onBeginPress={handleBeginPress} />
        </View>
      )}

      {isOnboardEffect && (
        <View style={styles.lottieContainer}>
          <LottieView
            loop={false}
            autoPlay={true}
            style={styles.lottie}
            source={require("../assets/Ripple-Grey.json")}
          />
        </View>
      )}

      {isOnboardWelcome && (
        <View style={styles.centerContainer}>
          <OnboardWelcome />
        </View>
      )}

      {isOnboardingChat && (
        <View style={isMedium ? styles.chatContainer : styles.mobileChatContainer}>
          <ChatContainer chatData={chatData?.getChatByContextAndParams} />
        </View>
      )}

      <MotiView
        style={styles.chatBarContainer}
        animate={{
          scale: state.meta.isExpanded ? 1 : 0,
          opacity: state.meta.isExpanded ? 1 : 0,
        }}
        transition={{ type: "timing", duration: 650 }}>
        {isOnboarded ? (
          <>
            <CustomButton
              text="Finish Onboarding"
              size="m"
              styleType="primary"
              onPress={handleFinishOnboarding}
              textProps={{ numberOfLines: 1 }}
            />
            <CustomSpacing />
          </>
        ) : (
          <ChatBarContainer onSubmit={handleChatSubmit} />
        )}
      </MotiView>

      {isOnboardBegin && (
        <View style={styles.canvasContainer}>
          <Canvas>
            <ambientLight />
            <pointLight />
            <CanvasController />
          </Canvas>
        </View>
      )}
    </OnboardingLayout>
  );
}

const styles = StyleSheet.create({
  canvasContainer: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
  },
  chatBarContainer: {
    zIndex: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  lottieContainer: {
    flex: 1,
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  lottie: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 2,
  },
  chatContainer: {
    flex: 1,
    paddingHorizontal: 40,
    paddingVertical: 10,
    zIndex: 2,
  },
  mobileChatContainer: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 10,
    zIndex: 2,
  },
});

export default OnboardingView;
