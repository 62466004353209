import { useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { useAppState, actions } from "../contexts/AppStateContext";
import { View, StyleSheet, Pressable } from "react-native";
import DefaultLayout from "../layouts/DefaultLayout";
import TaskListContainer from "../containers/tasks/TaskListContainer/TaskListContainer";
import CustomText from "../components/common/general/CustomText/CustomText";
import { useTodosQuery, useMeQuery } from "../graphql/generated/graphql";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import TaskFormModalContainer from "../containers/tasks/TaskFormModalContainer/TaskFormModalContainer";
import { PlusCircle } from "../components/svgIcons";
import { ScrollView } from "react-native-gesture-handler";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import Loader from "../components/common/status/Loader/Loader";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import CalendarContainer from "../containers/calendar/CalendarContainer";
import { LeftArrow, RightArrow } from "../components/svgs/common";
import { DatePickerModal } from "react-native-paper-dates";
import { calendarEvents } from "../services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MotiView } from "moti";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import SelectableMenu from "../components/navigation/SelectableMenu/SelectableMenu";
import EventFormModalContainer from "../containers/events/EventFormModalContainer/EventFormModalContainer";
import TabNavigator from "../components/common/general/TabNavigator/TabNavigator";
import JourneysView from "./JourneysView";
import { useIsFocused } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";
import AccordionListContainer from "../containers/tasks/AccordionListContainer/AccordionListContainer";
import Sentry from "../utils/sentry";
import { shadowStyles } from "../styles/shadow";

function ProductivityView() {
  const isFocused = useIsFocused();
  const { params } = useRoute();

  const { isMedium, isXLarge } = useResponsiveScreen();
  const [selectedMenu, setSelectedMenu] = useState("Tasks");
  const [isCreatingJourney, setIsCreatingJourney] = useState(false);
  const handleSelect = (item) => {
    setSelectedMenu(item);
  };

  const [activeTab, setActiveTab] = useState(params?.tab || "tasks");

  const tabs = [
    {
      key: "tasks",
      title: "Active Tasks",
    },
    {
      key: "journeys",
      title: "Journeys",
    },
    {
      key: "calendar",
      title: "Calendar",
    },
    {
      key: "completed",
      title: "Completed Tasks",
    },
  ];

  // isGcalConnected();
  const { data: userData } = useMeQuery();
  const isConnectedGoogleCal = !!userData?.me?.googleCalendarAuthToken;

  //Calendar
  const [currentDate, setCurrentDate] = useState(new Date());

  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [refreshEvents, setRefreshEvents] = useState(false);

  const onEventCreated = () => {
    setRefreshEvents(true);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setEventsLoading(true);
        const token = await AsyncStorage.getItem("token");
        let offset = currentDate.getTimezoneOffset();
        offset = offset / 60;
        const formattedDate = formatDate(currentDate);
        const data = await calendarEvents(token, formattedDate, offset);
        setEvents(data);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setEventsLoading(false);
      }
    };
    fetchEvents();
    if (refreshEvents) {
      setRefreshEvents(false);
    }
  }, [currentDate, refreshEvents]);

  const formatDate = (date) => {
    return `${date.toLocaleDateString("en-US", {
      month: "long",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const handlePrevDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  //Date Picker
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const showDatePicker = () => {
    setIsDatePickerVisible(true);
  };

  const onDateConfirmed = (params) => {
    setIsDatePickerVisible(false);
    setSelectedDate(params.date);
    setCurrentDate(params.date);
  };

  const onDatePickerDismissed = () => {
    setIsDatePickerVisible(false);
  };

  const posthog = usePostHog();
  const { dispatch } = useAppState();

  const [modalVisible, setModalVisible] = useState(false);
  const [eventModalVisible, setEventModalVisible] = useState(false);

  const { data, loading, error, refetch } = useTodosQuery({});

  useEffect(() => {
    posthog?.capture("Todos Page Visited");
  }, []);

  useEffect(() => {
    if (isFocused) {
      refetch();
    }
  }, [isFocused, refetch]);

  const handleModalOpen = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setModalVisible(true);
  };

  const handleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setModalVisible(false);
  };

  const handleEventModalOpen = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEventModalVisible(true);
  };

  const handleEventModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setEventModalVisible(false);
  };

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </DefaultLayout>
    );
  }
  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  const incompleteTasks = data.todos.filter((todo) => !todo.completed);
  const completedTodos = data.todos.filter((todo) => todo.completed);

  return (
    <DefaultLayout>
      {isMedium ? (
        <>
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={10}
            transition={{ type: "timing", duration: 500 }}>
            <CustomText role="header" text="Productivity" size="l" textType="display" weight="ultraBold" />
          </MotiView>
          <CustomSpacing type="vertical" size="s" />
          <TabNavigator
            tabData={tabs}
            activeTab={activeTab}
            onTabPress={(key) => {
              setActiveTab(key);
            }}
          />
          <CustomSpacing type="vertical" size="m" />
          {activeTab === "tasks" && (
            <View style={styles.rowContainer}>
              <View style={[styles.tasksScroll, { paddingHorizontal: 0 }]}>
                <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
                  <MotiView
                    from={{
                      opacity: 0,
                      translateY: 20,
                      scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      translateY: 0,
                      scale: 1,
                    }}
                    delay={200}
                    transition={{ type: "timing", duration: 600 }}>
                    <View style={styles.subheaderContainer}>
                      <CustomButton
                        text={" Add a Task"}
                        styleType="primaryLight"
                        onPress={handleModalOpen}
                        leftIcon={<PlusCircle />}
                        aria-label="Add a Task"
                        style={[styles.addButton, shadowStyles.smallShadowButton]}
                      />
                    </View>
                  </MotiView>
                  <CustomSpacing type="vertical" size="s" />
                  <AccordionListContainer tasks={incompleteTasks} />
                </ScrollView>
              </View>
            </View>
          )}

          {activeTab === "journeys" && (
            <JourneysView isCreatingJourney={isCreatingJourney} setIsCreatingJourney={setIsCreatingJourney} />
          )}
          {activeTab === "calendar" && (
            <View style={styles.agendaScroll}>
              <View style={styles.agendaScroll}>
                <View style={styles.subheaderContainer}>
                  <Pressable onPress={() => setCurrentDate(new Date())} role="button" aria-label="Set current date">
                    <CustomText text="Daily Agenda" size="xxl" weight="bold" />
                  </Pressable>
                  {isConnectedGoogleCal && (
                    <CustomButton
                      text={"Add an Event"}
                      styleType="primaryLight"
                      onPress={handleEventModalOpen}
                      leftIcon={<PlusCircle />}
                    />
                  )}
                </View>
                <View>
                  <View style={styles.datePicker}>
                    {isXLarge && (
                      <>
                        <CustomButton
                          aria-label="Previous Day"
                          styleType="transparent"
                          rightIcon={<LeftArrow />}
                          onPress={handlePrevDay}
                        />
                        <CustomButton
                          aria-label="Next Day"
                          styleType="transparent"
                          rightIcon={<RightArrow />}
                          onPress={handleNextDay}
                        />
                      </>
                    )}
                    <Pressable onPress={showDatePicker} role="button" aria-label="Set date">
                      <CustomText useTranslationText={false} bold="true" text={formatDate(currentDate)} />
                    </Pressable>
                  </View>
                </View>
                <CustomSpacing type="vertical" size="s" />
                <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
                  {eventsLoading ? (
                    <View style={styles.loaderContainer}>
                      <Loader />
                    </View>
                  ) : (
                    <CalendarContainer isGcal={isConnectedGoogleCal} events={events} />
                  )}
                </ScrollView>
              </View>
            </View>
          )}
          {activeTab === "completed" &&
            (completedTodos.length > 0 ? (
              <View style={styles.rowContainer}>
                <View style={[styles.tasksScroll, { paddingHorizontal: 0 }]}>
                  <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
                    <MotiView
                      from={{
                        opacity: 0,
                        translateY: 20,
                        scale: 1,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        scale: 1,
                      }}
                      delay={400}
                      transition={{ type: "timing", duration: 600 }}>
                      <CustomSpacing type="vertical" size="l" />
                    </MotiView>
                    <TaskListContainer onJourneyPathPress tasks={completedTodos} />
                  </ScrollView>
                </View>
              </View>
            ) : null)}
        </>
      ) : (
        <>
          <CustomText text="Productivity" size="s" weight="ultraBold" textType="display" style={{ color: "#101828" }} />
          <CustomSpacing type="vertical" size="xs" />
          <SelectableMenu
            items={["Tasks", "Journeys", "Completed", "Calendar"]}
            selectedItem={selectedMenu}
            onSelect={handleSelect}
          />
          <CustomSpacing type="vertical" size="m" />

          {selectedMenu === "Tasks" && (
            <View style={styles.tasksScroll}>
              <ScrollView style={{ height: 20 }} showsVerticalScrollIndicator={false}>
                <MotiView
                  from={{ opacity: 0, translateY: 20, scale: 1 }}
                  animate={{ opacity: 1, translateY: 0, scale: 1 }}
                  delay={200}
                  transition={{ type: "timing", duration: 600 }}>
                  <View style={styles.subheaderContainer}>
                    <CustomText text="Active Tasks" size="l" weight="ultraBold" style={{ color: "#101828" }} />
                    <CustomButton
                      text="Add Task"
                      styleType="primaryTransparent"
                      onPress={handleModalOpen}
                      leftIcon={<PlusCircle style={{ marginRight: 4 }} />}
                      textStyle={{ fontSize: 14 }}
                      bold
                    />
                  </View>
                </MotiView>
                <CustomSpacing type="vertical" size="s" />
                <TaskListContainer onJourneyPathPress tasks={incompleteTasks} />
              </ScrollView>
            </View>
          )}
          {selectedMenu === "Journeys" && <JourneysView />}
          {selectedMenu === "Calendar" && (
            <View style={styles.agendaScroll}>
              <View style={styles.subheaderContainer}>
                <Pressable role="button" aria-label="Set current date" onPress={() => setCurrentDate(new Date())}>
                  <CustomText text="Daily Agenda" size="xxl" weight="bold" />
                </Pressable>
                {isConnectedGoogleCal && (
                  <CustomButton
                    text={"Add an Event"}
                    styleType="primaryLight"
                    onPress={handleEventModalOpen}
                    leftIcon={<PlusCircle />}
                  />
                )}
              </View>
              <View>
                <View style={styles.datePicker}>
                  <CustomButton
                    aria-label="Previous Day"
                    styleType="transparent"
                    rightIcon={<LeftArrow />}
                    onPress={handlePrevDay}
                  />
                  <CustomButton
                    aria-label="Next Day"
                    styleType="transparent"
                    rightIcon={<RightArrow />}
                    onPress={handleNextDay}
                  />
                  <Pressable role="button" aria-label="Set date" onPress={showDatePicker}>
                    <CustomText useTranslationText={false} weight="bold" text={formatDate(currentDate)} />
                  </Pressable>
                </View>
              </View>
              <CustomSpacing type="vertical" size="s" />
              <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
                {eventsLoading ? (
                  <View style={styles.loaderContainer}>
                    <Loader />
                  </View>
                ) : (
                  <CalendarContainer isGcal={isConnectedGoogleCal} events={events} />
                )}
              </ScrollView>
            </View>
          )}

          {selectedMenu === "Completed" && completedTodos.length > 0 && (
            <>
              <CustomSpacing type="vertical" size="l" />
              {/* <CustomText
                text="Completed"
                size="l"
                weight="ultraBold"
                style={{ color: "#101828" }}
              /> */}
              <CustomSpacing type="vertical" size="s" />
              <TaskListContainer onJourneyPathPress tasks={completedTodos} />
            </>
          )}
        </>
      )}
      <TaskFormModalContainer visible={modalVisible} onClose={handleModalClose} />
      <EventFormModalContainer
        visible={eventModalVisible}
        onClose={handleEventModalClose}
        onEventCreated={onEventCreated}
      />
      <DatePickerModal
        locale="en"
        mode="single"
        visible={isDatePickerVisible}
        onDismiss={onDatePickerDismissed}
        date={selectedDate}
        onConfirm={onDateConfirmed}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  subheaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: -10,
    paddingLeft: 0,
  },
  rowContainer: {
    flex: 1,
    flexDirection: "row",
  },
  tasksScroll: {
    flex: 6,
  },
  agendaScroll: {
    flex: 4,
    paddingHorizontal: 10,
  },
  datePicker: {
    flexDirection: "row",
    alignItems: "center",
  },
  scrollView: {
    height: 20,
    paddingHorizontal: 0,
  },
  addButton: {
    borderWidth: 1,
    borderColor: "rgba(5, 6, 15, 0.1)",
  },
});

export default ProductivityView;
