import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useAuth } from "../hooks/useAuth";
import BlankLayout from "../layouts/BlankLayout";
import Loader from "../components/common/status/Loader/Loader";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { useCallback } from "react";

function LoginLTIView() {
  const route = useRoute();
  const { login } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  useFocusEffect(
    useCallback(() => {
      if (route.params?.token) {
        login(route.params.token);
      }

      navigation.navigate("My Day");
    }, [navigation, route])
  );

  return (
    <BlankLayout>
      <Loader />
    </BlankLayout>
  );
}

export default LoginLTIView;
