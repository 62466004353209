import { Platform } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import { createCheckoutSession, stripeManage } from "../../../services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import handleOpenLink from "../../../utils/handleOpenLink";
import PlanCard from "../../../components/profile/PlanCard/PlanCard";
import { useMeQuery } from "../../../graphql/generated/graphql";

function PlanCardContainer({ userData }) {
  const { data: meData } = useMeQuery();
  const appConfig = useAppConfig();
  const companionName = appConfig.companionName;

  const handleManagePress = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");

    const data = await stripeManage(token);
    const { url } = data;

    await handleOpenLink(url, newWindow);
  };

  const handleUpgradePress = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");
    const data = await createCheckoutSession(token);

    const { url } = data;

    await handleOpenLink(url, newWindow);
  };

  return (
    <PlanCard
      isPro={userData?.me?.isPro}
      companionName={companionName}
      onManagePress={handleManagePress}
      onUpgradePress={handleUpgradePress}
      trialEndDate={meData?.me?.trialEndDate}
    />
  );
}

export default PlanCardContainer;
