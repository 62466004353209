import React from "react";
import { TextInput, StyleSheet } from "react-native";

function CustomTextInput({ value, onChangeText, placeholder, style, ...props }) {
  const combinedStyles = [styles.input, style];

  return (
    <TextInput
      style={combinedStyles}
      value={value}
      onChangeText={(e) => onChangeText(e)}
      placeholder={placeholder}
      placeholderTextColor="#A1A1A1"
      ref={props.inputRef}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    color: "#1F2937",
    fontSize: 14,
    borderRadius: 8,
    width: "100%",
    padding: 10,
  },
});

export default CustomTextInput;
