import * as Sentry from "@sentry/react-native";

const isStage = window.location.hostname === "app.axiostage.xyz";

const isDev = window.location.hostname === "axiodev.xyz";

const isProd = !isDev && !isStage;

export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation({
  enableTimeToInitialDisplay: true,
});

Sentry.init({
  dsn: "https://b41d994af645ceb351dc6ae3942f4968@o4507644674834432.ingest.us.sentry.io/4507914951917568",
  integrations: [new Sentry.ReactNativeTracing({ routingInstrumentation })],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  environment: isProd ? "production" : isStage ? "stage" : "development",
  enabled: isProd || isStage,
});

export default Sentry;
