import { actions } from "../contexts/AppStateContext";

export const dispatchActions = (dispatch, message) => {
  const { meta, state, content, audio } = message;

  if (meta) {
    dispatch({ type: actions.SET_META, payload: meta });
  }

  if (state) {
    if (state.general) {
      dispatch({ type: actions.SET_GENERAL_STATE, payload: state.general });
    }

    if (state.learning) {
      if (state.learning.state) {
        dispatch({
          type: actions.SET_LEARNING_STATE,
          payload: state.learning.state,
        });
      }
      if (state.learning.topic) {
        dispatch({
          type: actions.SET_LEARNING_TOPIC,
          payload: state.learning.topic,
        });
      }
      if (state.learning.topicAncestors) {
        dispatch({
          type: actions.SET_LEARNING_TOPIC_ANCESTORS,
          payload: state.learning.topicAncestors,
        });
      }
      if (state.learning.label) {
        dispatch({
          type: actions.SET_LEARNING_LABEL,
          payload: state.learning.label,
        });
      }
      if (state.learning.isUnitAssessment) {
        dispatch({
          type: actions.SET_LEARNING_ASSESSMENT_DETAILS,
          payload: {
            isUnitAssessment: state.learning.isUnitAssessment,
            subchapterName: state.learning.subchapterName,
            subchapterDescription: state.learning.subchapterDescription,
            objectives: state.learning.objectives,
          },
        });
      }
    }
    if (state.view) {
      dispatch({ type: actions.SET_VIEW, payload: state.view });
    }
  }

  if (content) {
    dispatch({
      type: actions.SET_CONTENT,
      payload: content,
    });
  }

  if (audio) {
    dispatch({
      type: actions.SET_AUDIO,
      payload: {
        type: audio.type,
        data: audio.data,
      },
    });
  }
  // Add other conditions to dispatch other actions based on the message content
};
