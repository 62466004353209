import { View, Text, StyleSheet } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";

function UserInitial({ initial }) {
  const { primaryColor } = useAppConfig();

  const combinedStyle = [styles.container, { backgroundColor: primaryColor }];

  return (
    <View style={combinedStyle}>
      <Text style={styles.text}>{initial}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 24,
    width: 24,
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
  },
});

export default UserInitial;
