import React from "react";
import { View, Pressable, StyleSheet, Text } from "react-native";
import { MotiView } from "moti";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { Checked, UnChecked, Repeat, JourneyPath, Calendar, EditTask, SparkleThin } from "../../svgs/common";
import RoundedLabel from "../../common/label/RoundedLabel/RoundedLabel";
import CustomText from "../../common/general/CustomText/CustomText";
import PriorityIndicator from "../PriorityIndicator/PriorityIndicator";
import Tooltip from "../../common/tooltip/Tooltip";
import { shadowStyles } from "../../../styles/shadow";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useChatBar, ConversationType, Context } from "../../../contexts/ChatBarContext";
import useWebSocket from "../../../hooks/useWebSocket";
import { useRoute } from "@react-navigation/native";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function Task({
  title,
  onCheckboxToggle,
  onJourneyPathPress,
  completed,
  priority = "medium",
  date,
  isRepeating,
  isPartOfJourney,
  onTaskPress,
  isJourneyView,
  isDeleted,
  isMoved,
  onEdit,
  journeyId,
}) {
  const { isMedium } = useResponsiveScreen();

  const formattedTitle = capitalizeFirstLetterOfEachWord(title);
  const { formattedDate, color, bgColor: backgroundColor } = formatDateRelativeToToday(new Date(date));
  const { sendMessage } = useWebSocket();
  const route = useRoute();
  const {
    setShowChat,
    setMetaIsLoading,
    mapRouteToContext,
    setJourneyMessage,
    setConversationType,
    setActiveContextAndObjectId,
  } = useChatBar();

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "low":
        return "#F3FEE7"; // Green
      case "medium":
        return "#FFFAEB"; // Yellow
      case "high":
        return "#FFF1F3"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case "low":
        return "#4BA30D"; // Green
      case "medium":
        return "#F79009"; // Yellow
      case "high":
        return "#F63D68"; // Red
      default:
        return "#CCC"; // Default color
    }
  };

  const getPriority = (priority) => {
    switch (priority) {
      case "medium":
        return "Med";
      case "high":
        return "High";
      case "low":
        return "Low";
      default:
        return priority; // Fallback, although this should not be needed
    }
  };

  const handleGetHelpFromAxioJourney = async () => {
    const token = await AsyncStorage.getItem("token");
    setConversationType(ConversationType.JOURNEY);
    setJourneyMessage({ journeyId });
    setActiveContextAndObjectId({
      context: Context.JOURNEY,
      objectId: journeyId,
    });

    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: ConversationType.JOURNEY,
        context: Context.JOURNEY,
        params: { id: journeyId },
        journey: {
          journeyId: journeyId,
        },
      },
      content: {
        message: "Help me get started with this journey task",
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
    // onClose();
  };

  const handleGetHelpFromAxio = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "GENERAL_CONVERSATION",
        context: mapRouteToContext(route.name),
        params: route.params,
      },
      content: {
        message: "Help me get started with the task " + title,
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
    // onClose();
  };

  return (
    <MotiView
      aria-label="Task card"
      from={{
        opacity: 0,
        translateX: -400,
      }}
      animate={{
        opacity: 1,
        translateX: 0,
      }}
      delay={200}
      transition={{ type: "spring" }}>
      {isDeleted && (
        <View style={[isMedium ? styles.card : styles.cardMobile, shadowStyles.primary, styles.deletedTask]}>
          <CustomText text="Task Deleted" style={[styles.cardText, { color: "#C01048" }]} />
        </View>
      )}
      {(isMoved === "Active" || isMoved === "Completed") && (
        <View style={[isMedium ? styles.card : styles.cardMobile, shadowStyles.primary, styles.movedTask]}>
          <CustomText text={`Task moved to “${isMoved} Tasks”`} style={[styles.cardText, { color: "#3B7C0F" }]} />
        </View>
      )}
      {!isDeleted && !isMoved && (
        <Pressable onPress={onTaskPress} accessibilityHint="Press to see card details">
          {isMedium ? (
            <View style={[styles.card, shadowStyles.primary]}>
              <View style={styles.leftContainer}>
                {isRepeating && (
                  <View role="img" aria-label="Is repeating">
                    <Repeat />
                  </View>
                )}
                <View style={styles.textContainer}>
                  <CustomText
                    useTranslationText={false}
                    text={formattedTitle}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    size={"s"}
                    style={{
                      flexShrink: 1,
                      fontWeight: "700",
                    }}
                  />
                </View>
                {completed && (
                  <RoundedLabel
                    text="Complete"
                    style={styles.completedContainer}
                    textStyle={styles.completedText}
                    padding={[6, 4]}
                  />
                )}
              </View>

              <View style={[styles.rightContainer, { justifyContent: "flex-start" }]}>
                {!completed && (
                  <>
                    <View>
                      <View style={[styles.roundedLabel, { backgroundColor: getPriorityColor(priority) }]}>
                        <PriorityIndicator priority={priority} size="small" />
                        <CustomText
                          text={`Priority: ${getPriority(priority)}`}
                          style={{ color: "#344054", marginLeft: 5 }}
                          size="xs"
                          weight="bold"
                          useTranslationText={false}
                        />
                      </View>
                    </View>
                    <CustomSpacing size="s" type="horizontal" />
                    <Calendar style={{ height: 16, width: 16 }} />
                    <RoundedLabel
                      text={formattedDate}
                      style={{ backgroundColor, borderRadius: 8 }}
                      textStyle={{ color: "#344054", fontSize: 12, fontWeight: 600, fontFamily: "Inter" }}
                      padding={[4, 4]}
                    />
                    <CustomSpacing size="xl" type="horizontal" />
                    <Tooltip content="Get help from Axio" offset={10} horizontalOffset={-60}>
                      <Pressable
                        aria-label="Get help from Axio"
                        role="button"
                        onPress={isPartOfJourney ? handleGetHelpFromAxioJourney : handleGetHelpFromAxio}>
                        <SparkleThin fill={"#98A2B3"} />
                      </Pressable>
                    </Tooltip>
                    <CustomSpacing size="m" type="horizontal" />
                    <Tooltip content="Edit Task" offset={0} horizontalOffset={-30}>
                      <Pressable aria-label="Edit Task" role="button" onPress={onEdit}>
                        <EditTask fill={"#98A2B3"} />
                      </Pressable>
                    </Tooltip>
                    <CustomSpacing size="m" type="horizontal" />
                  </>
                )}

                {isPartOfJourney && !isJourneyView && (
                  <>
                    <Tooltip content="View Journey" offset={0}>
                      <Pressable
                        aria-label="Navigate to journey page"
                        role="button"
                        onPress={onJourneyPathPress}
                        style={styles.additionalButton}>
                        <JourneyPath />
                      </Pressable>
                    </Tooltip>
                    <CustomSpacing size="m" type="horizontal" />
                  </>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {!completed || !isRepeating ? (
                    <Tooltip
                      content={completed ? "Mark as incomplete" : "Mark as complete"}
                      horizontalOffset={-60}
                      offset={-15}>
                      <Pressable
                        onPress={onCheckboxToggle}
                        style={styles.checkbox}
                        role="checkbox"
                        aria-checked={completed}
                        aria-label="Toggle task completion">
                        {completed ? <Checked /> : <UnChecked />}
                      </Pressable>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            </View>
          ) : (
            <View style={[styles.cardMobile, shadowStyles.primary]}>
              <View style={styles.textContainer}>
                <CustomText
                  text={formattedTitle}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  bold="bold"
                  useTranslationText={false}
                />

                <View style={{ flexDirection: "row", marginVertical: 3 }}>
                  {!completed ? (
                    <RoundedLabel
                      text={
                        <Text>
                          <Text style={{ color: "grey" }}>Priority: </Text>
                          <Text style={{ color: getPriorityText(priority) }}>
                            {capitalizeFirstLetterOfEachWord(priority)}
                          </Text>
                        </Text>
                      }
                      style={{
                        backgroundColor: getPriorityColor(priority),
                        borderRadius: 8,
                      }}
                      textStyle={{
                        color: getPriorityText(priority),
                      }}
                      padding={[6, 4]}
                    />
                  ) : (
                    <RoundedLabel
                      text="Complete"
                      style={styles.completedContainer}
                      textStyle={styles.completedText}
                      padding={[6, 4]}
                    />
                  )}

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: 8,
                    }}>
                    <Calendar style={{ height: 24, width: 24 }} />
                    <RoundedLabel
                      text={formattedDate}
                      style={{ backgroundColor, borderRadius: 8 }}
                      textStyle={{ color, fontSize: 14 }}
                      padding={[6, 4]}
                    />
                    {isRepeating && (
                      <View style={{ marginHorizontal: 10 }} role="img" aria-label="Is repeating">
                        <Repeat />
                      </View>
                    )}
                  </View>
                </View>
              </View>

              <View
                style={{
                  marginVertical: 8,
                  borderTopWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#D3D3D3",
                }}
              />

              <View
                style={[
                  styles.rightContainer,
                  {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                ]}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}>
                  {!isRepeating || !completed ? (
                    <Pressable
                      onPress={onCheckboxToggle}
                      style={styles.row}
                      role="checkbox"
                      aria-checked={completed}
                      aria-label="Toggle task completion">
                      <View style={styles.checkbox}>{completed ? <Checked /> : <UnChecked />}</View>
                      <Text>Complete</Text>
                    </Pressable>
                  ) : (
                    <></>
                  )}
                </View>

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {isPartOfJourney && !isJourneyView && (
                    <Pressable
                      onPress={onJourneyPathPress}
                      style={[styles.additionalButton, styles.row]}
                      aria-label="Navigate to journey page"
                      role="button">
                      <JourneyPath />
                      <Text>View Journey</Text>
                    </Pressable>
                  )}
                </View>
              </View>
            </View>
          )}
        </Pressable>
      )}
    </MotiView>
  );
}

function capitalizeFirstLetterOfEachWord(str) {
  if (!str) return "";
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

function formatDateRelativeToToday(targetDate) {
  const formattedDate = formatDateAsMMDDYYYY(targetDate);

  const color = "#000";
  const bgColor = "#FFF";

  return { formattedDate, color, bgColor };
}

function formatDateAsMMDDYYYY(date) {
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
}

const styles = StyleSheet.create({
  card: {
    paddingVertical: 10,
    borderRadius: 10,
    marginVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardMobile: {
    paddingVertical: 20,
    borderRadius: 10,
    marginVertical: 10,
    paddingHorizontal: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
    gap: 10,
    paddingRight: 5,
  },
  textContainer: {
    alignItems: "flex-start",
    flexShrink: 1,
  },
  rightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  completedContainer: {
    borderRadius: 100,
    backgroundColor: "#66C61C",
  },
  completedText: {
    color: "#fff",
    padding: 4,
  },
  checkbox: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  deletedTask: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFD2D2",
    minHeight: 60,
  },
  movedTask: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E3FBCC",
    minHeight: 60,
  },
  cardText: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24,
  },
  roundedLabel: {
    borderRadius: 100,
    paddingHorizontal: 13,
    paddingVertical: 8,
    backgroundColor: "#fff",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default Task;
