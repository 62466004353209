import { View, StyleSheet, Platform } from "react-native";
import useWebSocket from "../hooks/useWebSocket";
import { useNavigation, useRoute } from "@react-navigation/native";
import getLearningRoute from "../utils/getLearningRoute";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LessonHeader from "../components/learning/lesson/LessonHeader/LessonHeader";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import YoutubeSearch from "../components/youtube/youtube";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import LessonBreadcrumbs from "../components/learning/lesson/LessonBreadcrumbs/LessonBreadcrumbs";
import { useEffect, useState } from "react";
import LessonFeedbackModal from "../components/learning/lesson/modals/LessonFeedbackModal/LessonFeedbackModal";
import { sendUserIdEmail } from "../services";
import { useUpdateTopicMutation } from "../graphql/generated/graphql";
import LessonRegenerateModal from "../components/learning/lesson/modals/RegenerateModal/LessonRegenerateModal";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CheckedCircle from "../components/svgs/common/CheckedCircle";
import { useAppConfig } from "../AppConfigProvider";
import { actions, useAppState } from "../contexts/AppStateContext";
import {
  useGetLearningObjectiveByIdQuery,
  useUpdateLearningObjectiveCompletionMutation,
  useGetMasteryQuery,
  useGetLearningObjectivesBySubchapterQuery,
  GetLearningObjectiveByIdDocument,
  GetMasteryDocument,
  TableOfContentsDocument,
  RecentTracksDocument,
  UserProgressDocument,
} from "../graphql/generated/graphql";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../components/common/general/CustomText/CustomText";
import DefaultLayout from "../layouts/DefaultLayout.web";
import { announceForAccessibility } from "../utils/accessibility";
import Sentry from "../utils/sentry";

function LessonVideoView() {
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation();
  const route = useRoute();
  const { isMedium } = useResponsiveScreen();
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(null);
  const [isRegenerateModalVisible, setIsRegenerateModalVisible] = useState(false);
  const [isLoadingFeedbackModal, setIsLoadingFeedbackModal] = useState(false);
  const [errorMessageFeedbackModal, setErrorMessageFeedbackModal] = useState("");
  const [errorMessageRegenerateModal, setErrorMessageRegenerateModal] = useState("");
  const [youtubeKeyword, setYoutubeKeyword] = useState("");
  const [updateTopicMutation] = useUpdateTopicMutation();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const { dispatch } = useAppState();
  const { ancestors, name } = getLearningRoute(route.params);
  const param = route.params;
  const id = param.loId;
  const subchapter = param.subchapter;
  const [shouldRefetch, setShouldRefetch] = useState(false);

  useEffect(() => {
    if (Platform.OS === "ios" && errorMessageFeedbackModal) {
      announceForAccessibility({ message: "Error alert" + errorMessageFeedbackModal, queue: false });
    }
    if (Platform.OS === "ios" && !isLoadingFeedbackModal) {
      announceForAccessibility({ message: "Modal is loaded", queue: false });
    }
    if (Platform.OS === "ios" && errorMessageRegenerateModal) {
      announceForAccessibility({ message: "Error alert" + errorMessageRegenerateModal, queue: false });
    }
  }, [errorMessageFeedbackModal, isLoadingFeedbackModal, errorMessageRegenerateModal]);

  const [updateLearningObjectiveCompletion] = useUpdateLearningObjectiveCompletionMutation({
    refetchQueries: [
      {
        query: GetLearningObjectiveByIdDocument,
        variables: { loId: id },
      },
      {
        query: GetMasteryDocument,
        variables: {
          learningObjectiveId: id,
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [param.topic],
          hierarchyLabel: "Topic",
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [param.chapter],
          hierarchyLabel: "Chapter",
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [param.subchapter],
          hierarchyLabel: "Subchapter",
        },
      },
      {
        query: RecentTracksDocument,
      },
      {
        query: UserProgressDocument,
        variables: { nodeName: param.subfield, nodeLabel: "Subfield" },
      },
      {
        query: UserProgressDocument,
        variables: { nodeName: param.field, nodeLabel: "Field" },
      },
    ],
  });

  const { data } = useGetLearningObjectiveByIdQuery({
    variables: { loId: id },
  });

  const { data: masteryData, refetch: refetchMastery } = useGetMasteryQuery({
    variables: {
      learningObjectiveId: id,
    },
    skip: !id,
    onCompleted: () => setShouldRefetch(false),
  });

  const isUnitCompleted = masteryData?.getUserLearningObjectiveMastery?.totalMastery === 100;

  const { data: learningObjectivesData } = useGetLearningObjectivesBySubchapterQuery({
    variables: { learningObjectiveId: id },
  });

  const learningObjectives = learningObjectivesData?.getLearningObjectivesBySubchapter;

  const handleNextUnit = () => {
    if (!learningObjectives) return;

    const currentObjectiveIndex = learningObjectives.findIndex((lo) => lo.id === id);
    const nextLearningObjective = learningObjectives[currentObjectiveIndex + 1];

    if (!nextLearningObjective) return;

    const nextPage = nextLearningObjective.title === "Assessment Quiz" ? "Lesson Assessment" : "Lesson Activities";

    navigation.navigate(nextPage, {
      field: param.field,
      subfield: param.subfield,
      topic: param.topic,
      chapter: param.chapter,
      subchapter: param.subchapter,
      loTitle: nextLearningObjective.title,
      loId: nextLearningObjective.id,
    });
  };

  const markLearningObjectiveComplete = (loId, completed) => {
    updateLearningObjectiveCompletion({
      variables: {
        id: loId,
        completed: completed,
      },
    })
      .then(() => {
        setShouldRefetch(true);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  if (shouldRefetch) {
    refetchMastery();
  }

  useEffect(() => {
    const keyword = `Title: ${subchapter} | Description: ${data?.getLearningObjectiveById?.description}`;
    setYoutubeKeyword(keyword);
    return () => {
      setYoutubeKeyword("");
    };
  }, [route.params]);

  const handleBackToActivities = async () => {
    const token = await AsyncStorage.getItem("token");

    sendMessage({
      meta: {
        token,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        abort: true,
      },
      state: {
        general: "LEARNING",
        view: "LESSON",
        learning: {
          state: "TEXTBOOK",
          topic: name,
          topicAncestors: ancestors,
        },
      },
    });

    navigation.navigate("Lesson Activities", route.params);
  };

  const handleFeedbackModalSubmit = async (feedback, responseInput) => {
    const isModalPositive = isFeedbackModalVisible === "positive";

    setErrorMessageFeedbackModal("");
    setIsLoadingFeedbackModal(true);
    if (!responseInput || responseInput.length < 1) {
      setErrorMessageFeedbackModal("Please enter a valid response.");
      setIsLoadingFeedbackModal(false);
      return;
    }

    const token = await AsyncStorage.getItem("token");

    try {
      await updateTopicMutation({
        variables: {
          operationType: isModalPositive ? "upvote" : "downvote",
          topicName: route.params.topic,
        },
      });

      await sendUserIdEmail(
        token,
        `topic:${route.params.topic}, feedback: ${
          isModalPositive ? "positive feedback" : feedback
        }, message: ${responseInput}`,
        `User lesson ${isModalPositive ? "positive" : "negative"} feedback`
      );

      return handleCloseFeedbackModal();
    } catch (error) {
      setIsLoadingFeedbackModal(false);
      setErrorMessageFeedbackModal("Failed to send feedback. Please try again.");
      return;
    }
  };

  const handleCloseFeedbackModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setIsLoadingFeedbackModal(false);
    setErrorMessageFeedbackModal("");
    setIsFeedbackModalVisible(null);
  };

  const handleCloseRegenerateModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setErrorMessageRegenerateModal("");
    setIsRegenerateModalVisible(false);
  };

  const handleRegenerateVideos = (input) => {
    setErrorMessageRegenerateModal("");
    if (!input || input.length < 1) {
      setErrorMessageRegenerateModal("Please enter a valid response.");
      return;
    }
    setYoutubeKeyword(input);
    handleCloseRegenerateModal();
  };

  const handleOpenFeedbackModal = (value) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setIsFeedbackModalVisible(value);
  };

  const handleOpenRedoModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setIsRegenerateModalVisible(true);
  };

  return (
    <DefaultLayout menuType="learn">
      <View style={isMedium ? styles.container : { flex: 1 }}>
        <LessonBreadcrumbs
          onPress={handleBackToActivities}
          firstItem="Learning Activities"
          secondItem="Video Activity"
        />
        <LessonHeader
          tag={isMedium ? "" : "Activity"}
          title="Video Activity"
          showButtons={true}
          onThumbUpPress={() => handleOpenFeedbackModal("positive")}
          onThumbDownPress={() => handleOpenFeedbackModal("negative")}
          onRedoPress={handleOpenRedoModal}
        />

        <CustomSpacing type="vertical" size="l" />
        <ScrollView style={styles.scroll}>
          <CustomText
            useTranslationText={false}
            text={data?.getLearningObjectiveById.title}
            style={{ color: "#667085" }}
            weight="bold"
            size={isMedium ? "m" : "s"}
            role="header"
            aria-level="2"
          />
          <CustomText
            useTranslationText={false}
            text={data?.getLearningObjectiveById.description}
            style={{ color: "#667085" }}
            weight="normal"
            size={isMedium ? "m" : "s"}
          />
          <CustomSpacing type="vertical" size="l" />
          <YoutubeSearch isMobile={!isMedium} keyword={youtubeKeyword} />
        </ScrollView>
        <CustomSpacing type="vertical" size="l" />
        <View style={styles.bottomRow}>
          <CustomButton
            text={isUnitCompleted ? "Completed" : "Mark as completed"}
            styleType="primary"
            bold
            onPress={() => markLearningObjectiveComplete(id, !isUnitCompleted)}
            leftIcon={<CheckedCircle fill="white" />}
            style={[
              styles.unitButton,
              { justifyContent: "center", alignItems: "center", gap: 4 },
              { backgroundColor: isUnitCompleted ? "#66C61C" : primaryColor },
            ]}
          />
          <CustomButton
            text="Next"
            bold
            style={[styles.unitButton, { border: `2px solid ${primaryColor}` }]}
            textStyle={{ fontSize: 14 }}
            styleType="primaryLight"
            onPress={handleNextUnit}
            aria-label="Next unit"
          />
        </View>
        <CustomSpacing type="vertical" size="l" />
      </View>
      <LessonFeedbackModal
        visible={!!isFeedbackModalVisible}
        onClose={handleCloseFeedbackModal}
        isPositive={isFeedbackModalVisible === "positive"}
        onSubmit={handleFeedbackModalSubmit}
        errorMessage={errorMessageFeedbackModal}
        isLoading={isLoadingFeedbackModal}
      />
      <LessonRegenerateModal
        visible={isRegenerateModalVisible}
        onClose={handleCloseRegenerateModal}
        onSubmit={(input) => handleRegenerateVideos(input)}
        errorMessage={errorMessageRegenerateModal}
        isLoading={false}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 40,
    paddingBottom: 0,
  },
  unitButton: {
    padding: 12,
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  scroll: {
    height: 300,
  },
});

export default LessonVideoView;
