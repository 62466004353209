import { useNavigation, useRoute } from "@react-navigation/native";
import ContentMenu from "../../../components/navigation/ContentMenu/ContentMenu";
import {
  GetCourseDocument,
  useCreateAssignmentMutation,
  useCreateModuleMutation,
  useCreatePageMutation,
  useDeleteModuleCascadeMutation,
  useGetCourseQuery,
  useUpdateModuleMutation,
} from "../../../graphql/generated/graphql";
import { useState } from "react";
import { Alert, Text, View } from "react-native";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import Sentry from "../../../utils/sentry";

function CustomCourseworkMenuContainer() {
  const [editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible] = useState(null);
  const [editModuleNameModalVisible, setEditModuleNameModalVisible] = useState(false);
  const [addModuleModalVisible, setAddModuleModalVisible] = useState(false);
  const [deleteModuleModalVisible, setDeleteModuleModalVisible] = useState(false);

  const navigation = useNavigation();

  const { dispatch } = useAppState();

  const route = useRoute();
  const courseId = route.params?.courseId;

  const { data, loading } = useGetCourseQuery({
    variables: {
      id: courseId,
    },
  });

  const selectedCourse = data?.getCourse.data[0];

  const [createModuleMutation] = useCreateModuleMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [deleteModuleMutation] = useDeleteModuleCascadeMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [updateModuleMutation] = useUpdateModuleMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [createAssignmentMutation] = useCreateAssignmentMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  const [createPageMutation] = useCreatePageMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: selectedCourse?.id,
        },
        skip: !selectedCourse,
      },
    ],
  });

  if (loading)
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );

  const handleItemPress = (name, moduleName, _, type) => {
    const module = selectedCourse.modules.find((mod) => mod.title === moduleName);
    if (!module) {
      console.error(`Module with name "${moduleName}" not found.`);
      return;
    }

    let assignment;
    let page;

    if (type === "Assignment") {
      assignment = module.resources[0].assignments.find((assign) => assign.title === name);
      if (!assignment) {
        console.error(`Assignment with name "${name}" not found in module "${moduleName}".`);
        return;
      }
    } else {
      page = module.resources[0].pages.find((p) => p.title === name);
      if (!page) {
        console.error(`Page with name "${name}" not found in module "${moduleName}".`);
        return;
      }
    }

    navigation.navigate("Custom Coursework Modules", {
      courseId: selectedCourse.id,
      moduleIndex: selectedCourse.modules.indexOf(module),
      ...(type === "Assignment" && { assignmentIndex: module.resources[0].assignments.indexOf(assignment) }),
      ...(type === "Page" && { pageIndex: module.resources[0].pages.indexOf(page) }),
    });
  };

  const handleRenameModule = async (moduleName, newModuleName, order, moduleId) => {
    try {
      const response = await updateModuleMutation({
        variables: {
          id: moduleId,
          title: newModuleName,
          order: parseInt(order, 10),
        },
      });

      if (response?.data.updateModule.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setEditModuleNameModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to update module");
        Sentry.captureException(response?.data?.updateContentModuleName?.message);
        console.error("Failed to update module:", response?.data?.updateContentModuleName?.message);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error("Error updating module name:", e);
    }
  };

  const handleDeleteModule = async (moduleName, moduleId) => {
    try {
      const response = await deleteModuleMutation({
        variables: {
          id: moduleId,
        },
      });

      if (response?.data?.deleteModuleCascade?.success) {
        Alert.alert("Success", "Module deleted successfully");
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setDeleteModuleModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to delete module");
        Sentry.captureException(response.data?.deleteContentModule.message);
        Alert.alert("Error", response.data?.deleteContentModule.message || "Failed to delete module");
      }
    } catch (error) {
      Sentry.captureException(error);
      Alert.alert("Error", "An error occurred while deleting the module.");
    }
  };

  const handleAddModule = async (moduleName, order) => {
    try {
      const response = await createModuleMutation({
        variables: {
          parentCourseId: selectedCourse.id,
          title: moduleName,
          order: order,
        },
      });

      if (response?.data?.createModuleAndResource.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setAddModuleModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to add module");
        Sentry.captureException(response?.data?.addContentModule?.message);
        console.error("Failed to add module:", response?.data?.addContentModule?.message);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error("Error adding module:", e);
    }
  };

  const handleAddAssignmentOrPage = async (type, html, newName, dueDate, moduleName, resourceId) => {
    const cleanedHtml = html.replace(/\n\s+/g, "").trim();
    const encodedHtml = encodeURIComponent(cleanedHtml);

    try {
      if (type === "Page") {
        await createPageMutation({
          variables: {
            title: newName,
            body: encodedHtml,
            parentResourceId: resourceId,
          },
        });
      } else {
        await createAssignmentMutation({
          variables: {
            title: newName,
            dueDate: new Date(dueDate).toISOString(),
            body: encodedHtml,
            parentResourceId: resourceId,
          },
        });
      }

      dispatch({
        type: actions.SET_META,
        payload: { keypressIsListening: true },
      });
      setEditAssignmentOrPageModalVisible(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(`Error adding ${type}`, error);
    }
  };

  return (
    <>
      {selectedCourse ? (
        <ContentMenu
          modulesRoute={"Custom Coursework Modules"}
          data={selectedCourse}
          courseName={selectedCourse?.title}
          onItemPress={handleItemPress}
          onRenameModule={handleRenameModule}
          onDeleteModule={handleDeleteModule}
          onAddModule={handleAddModule}
          onAddAssignmentOrPage={handleAddAssignmentOrPage}
          editAssignmentOrPageModalState={[editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible]}
          addModuleModalState={[addModuleModalVisible, setAddModuleModalVisible]}
          deleteModuleModalState={[deleteModuleModalVisible, setDeleteModuleModalVisible]}
          editModuleNameModalState={[editModuleNameModalVisible, setEditModuleNameModalVisible]}
        />
      ) : (
        <View>
          <Text>No courses available</Text>
        </View>
      )}
    </>
  );
}

export default CustomCourseworkMenuContainer;
