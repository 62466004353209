import React from "react";
import { MotiView } from "moti";
import { View, Text, Pressable, StyleSheet } from "react-native";
import { shadowStyles } from "../../../styles/shadow";

function ClassCard({ title, onPress, color }) {
  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      delay={1000}
      transition={{ type: "timing", duration: 500 }}
      aria-label={`Class card ${title}`}>
      <View style={[styles.card, shadowStyles.smallCard]}>
        <View style={styles.infoContainer}>
          <View style={[styles.detailsContainer, { backgroundColor: color }]}>
            <Text style={styles.titleText} numberOfLines={2} ellipsizeMode="tail">
              {title}
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            <Pressable onPress={onPress} style={styles.startButton} role="link" aria-label={`View class ${title}`}>
              <Text style={styles.startButtonText}>View Class</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  card: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 10,
    flex: 1,
    maxWidth: 280,
    minWidth: 280,
    flexDirection: "column",
    borderRadius: 10,
    marginVertical: 10,
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  detailsContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    gap: 6,
    padding: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: "54%",
  },
  titleText: {
    alignSelf: "stretch",
    color: "#fff",
    fontSize: 24,
    fontWeight: "700",
    wordWrap: "break-word",
  },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-end",
    display: "inline-flex",
    backgroundColor: "#fff",
    padding: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    height: "46%",
  },
  startButton: {
    padding: 12,
    color: "#3E68FE",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    display: "flex",
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderStyle: "solid",
  },
  startButtonText: {
    color: "#3E68FE",
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 20,
    wordWrap: "break-word",
  },
  // circle: {
  //   position: "absolute",
  //   top: 8,
  //   right: 16,
  //   width: 24,
  //   height: 24,
  //   backgroundColor: "white",
  //   borderRadius: 16,
  //   borderWidth: 1,
  //   borderColor: "#fff",
  //   zIndex: 10,
  // },
  // hole: {
  //   position: "absolute",
  //   top: 5,
  //   right: 5,
  //   width: 12,
  //   height: 12,
  //   borderRadius: 6,
  // },
});

export default ClassCard;
